import {
  scaleOrdinal,
  scaleQuantile,
  ScaleQuantile,
  schemeCategory10,
} from 'd3';
import { color } from 'd3-color';
import { ScaleLinear, scaleLinear } from 'd3-scale';

export type Palette = string[];
export type CustomPaletteEntry = {
  color: string;
  fromValue: number;
  toValue: number;
};
export type CustomPalette = CustomPaletteEntry[];

export class ColorPaletteGenerator {
  private length: number = 5;

  constructor(
    private startColor: string,
    private endColor: string,
    length?: number
  ) {
    if (length) {
      this.length = length;
    }
  }

  public setLength(length: number): this {
    this.length = length;
    return this;
  }

  public getPalette(): Palette {
    const scale: ScaleLinear<any, any> = scaleLinear()
      .domain([1, this.length])
      .range([this.startColor, this.endColor] as any);

    let colors: string[] = [];

    for (let i = 1; i <= this.length; i++) {
      const c = color(scale(i))!.formatHex();
      colors.push(c);
    }

    return colors;
  }

  public getScale(domain: number[]): ScaleLinear<any, any> {
    return scaleLinear()
      .domain(domain)
      .range([this.startColor, this.endColor] as any);
  }

  static getQuantileScale(
    [min, max]: [number, number],
    colors: string[]
  ): ScaleQuantile<string, number> {
    return scaleQuantile([min, max], colors);
  }

  static isCustomPalete(obj: any): obj is CustomPaletteEntry {
    if ('string' === typeof obj || !('color' in obj)) {
      return false;
    }
    return true;
  }
}

export const Colors = {
  Primary: new ColorPaletteGenerator('#5eaabb', '#002c3e'),
  Purples: new ColorPaletteGenerator('#9c9ede', '#393b79'),
  Oranges: new ColorPaletteGenerator('#ffffcc', '#fd8d3c'),
  Reds: new ColorPaletteGenerator('#fee5d9', '#a50f15'),
  Greens: new ColorPaletteGenerator('#edf8e9', '#006d2c'),
  Blues: new ColorPaletteGenerator('#eff3ff', '#08519c'),
  Blacks: new ColorPaletteGenerator('#000000', '#969696'),
  WhiteToBlack: new ColorPaletteGenerator('#FFFFFF', '#969696'),
};

const ChartsColors2 = scaleOrdinal(schemeCategory10).range();

export const getChartColor = (index: number): string => {
  const i =
    index >= ChartsColors2.length ? index % ChartsColors2.length : index;
  return ChartsColors2[i];
};
