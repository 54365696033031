import { scaleThreshold, ScaleThreshold } from "d3";


// The map colors are based on the following color scheme:
export const COLORS = [
  '#6ad2e4',
  '#5eaabb',
  '#498e9f',
  '#347283',
  '#1f5668'
];

export const EMPTY_DATA_COLOR = '#A0A0A0';

export type ColorScaleLimits = {
  scale: ScaleThreshold<number, number, never>;
  limits: number[];
};

export const getCustomColorScale = (values: number[], colors: string[], forceAll?: boolean): ColorScaleLimits => {
  const sorted = values
    .filter((v) => !isNaN(v) && !!v)
    .sort((v1, v2) => v1 - v2);

  let limits: number[] = [];

  const forRange = 5;

  // Slice the limits into forRange parts
  const step = Math.floor(sorted.length / forRange);

  limits.push(sorted[0 * step]);
  limits.push(sorted[1 * step]);
  limits.push(sorted[2 * step]);
  limits.push(sorted[3 * step]);
  limits.push(sorted[4 * step]);
  limits.push(sorted[sorted.length - 1]);

  const scale = scaleThreshold()
    .domain(forceAll ? limits : limits.slice(1))
    .range(colors as any[]);

  return {
    scale,
    limits,
  };
};

export const getColorScale = (values: number[]): ColorScaleLimits => {

  return getCustomColorScale(values, COLORS);
};
