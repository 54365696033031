import { CancellablePromise } from 'domain/models/common.models';
import React from 'react';

export const useCancellablePromises = <T>() => {
  const pendingPromises = React.useRef<CancellablePromise<T>[]>([]);

  const appendPendingPromise = (promise: CancellablePromise<T>) =>
    (pendingPromises.current = [...pendingPromises.current, promise]);

  const removePendingPromise = (promise: CancellablePromise<T>) =>
    (pendingPromises.current = pendingPromises.current.filter(
      (p) => p !== promise
    ));

  const clearPendingPromises = () =>
    pendingPromises.current.map((p) => p.cancel());

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};
