import { rollup } from 'd3';

import jsPDF, { HTMLOptions } from 'jspdf';
import autoTable from 'jspdf-autotable';

import {
  AnalysisBranch,
  IndicatorData,
  IndicatorHeaderEntry,
  IndicatorNotes,
  IndicatorNotesEntry,
  IndicatorRowData,
  IndicatorTableData,
  RestSortInfo,
  Variable,
} from 'domain/models';

import {
  ControllerUtils,
  MultiFilterOrIndicator,
  PropCode,
} from './controller.utils';
import { AnalysisDataService } from 'domain/services';
import { PDF_CONFIG } from 'domain/core/constants';
import { isReferenceValue } from '../isReferenceValue';

export type CurrentTableProps = {
  master: AnalysisBranch;
  selectedTable: MultiFilterOrIndicator;
  sortInfo: RestSortInfo | undefined;
};

export class Table {
  constructor(
    private tableData: IndicatorTableData,
    private data: IndicatorData,
    private configuration: AnalysisBranch
  ) {}

  /**
   * @returns
   */
  public getRows(): IndicatorRowData[][] {
    return this.tableData.rowData;
  }

  getTableSources() {
    return this.tableData?.sources;
  }

  getTableUnits() {
    return this.tableData?.units;
  }

  getHeadersEntry(): IndicatorHeaderEntry[][] {
    const headerRows: IndicatorHeaderEntry[][] = [];
    rollup(
      this.tableData.headers.map((h) => ({
        ...h,
        customCode: this.getHeaderEntryCode(h),
      })),
      (c) => c,
      (c) => c.level
    ).forEach((v) => headerRows.push(v));
    return headerRows;
  }

  getHeadConfiguration() {
    return {
      tableIdentifier: this.getTableIdentifier(),
      headerRows: this.tableData.headers,
    };
  }

  setData(data: IndicatorData): this {
    this.data = data;
    return this;
  }

  setTableData(tableData: IndicatorTableData): this {
    this.tableData = tableData;
    return this;
  }

  setConfiguration(configuration: AnalysisBranch): this {
    this.configuration = configuration;
    return this;
  }

  private getTableIdentifier(): string {
    const tableProp = ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
      this.configuration.tableSettings.tables[0]
    );

    const firstEntry = this.data.data[0];
    if (firstEntry) {
      const tableIdentifier = firstEntry[tableProp] as PropCode;
      return tableIdentifier || ``;
    }
    return '';
  }

  private getHeaderEntryCode(
    headerEntry: IndicatorHeaderEntry
  ): string | undefined {
    if (headerEntry.topHeaders) {
      let code = headerEntry.code;

      for (let topHeader of headerEntry.topHeaders) {
        code += `-${topHeader.code}`;
      }

      return code;
    }
  }

  static async exportTableAsPdf(
    current: CurrentTableProps,
    title: string,
    lang: string,
    comments?: IndicatorNotes,
    options?: HTMLOptions
  ): Promise<boolean> {

    try {
      const service = new AnalysisDataService();
      const pdfTable = (master: AnalysisBranch): AnalysisBranch => {
        const tableSettings = { ...master.tableSettings };
        tableSettings.columns = [Variable.Sex];
        tableSettings.rows = [Variable.Country, Variable.Year];
        return { ...master, tableSettings };
      };
      const data = await service.loadTableData(
        pdfTable(current.master),
        current.selectedTable,
        lang,
        current.sortInfo
      );
      const doc = new jsPDF('portrait', 'px', 'a4', true);
      doc.setFontSize(PDF_CONFIG.FONT_SIZE);
      const wrappedTitle = doc.splitTextToSize(title, PDF_CONFIG.MAX_TITLE_LENGTH);
      const titleHeight = wrappedTitle.length * PDF_CONFIG.TITLE_FONT_SIZE;
      doc.setFontSize(PDF_CONFIG.TITLE_FONT_SIZE);
      doc.text(wrappedTitle, doc.internal.pageSize.getWidth() / 2, PDF_CONFIG.MARGIN_Y, {
        align: 'center',
      });
      doc.setFontSize(PDF_CONFIG.FONT_SIZE);

      const generateCellValue = (cell: IndicatorRowData): string => {
        const commentText = (cell.data?.comments && cell.data?.comments.length > 0 ? ` (${cell.data.comments.join(", ")})` : ``);
        return cell.value + commentText;
      }

      const body = data.rowData.map((row) =>
        row
          .filter((cell) => cell.type !== 'MERGED')
          .map((cell) => ({ content: generateCellValue(cell), rowSpan: cell.rowspan }))
      );

      autoTable(doc, {
        startY: PDF_CONFIG.TABLE_START_Y + titleHeight,
        head: [data.headers.map((h) => h.value)],
        body: body,
        columnStyles: {
          0: { cellWidth: PDF_CONFIG.FIRST_COLUMN_WIDTH },
        },
        styles: {
          fontSize: PDF_CONFIG.FONT_SIZE,
          overflow: 'linebreak',
          cellPadding: PDF_CONFIG.CELL_PADDING,
        },
        // Apply bold text in all cells that contains the word "Total"
        didParseCell: (data) => {
          const raw = data.cell.raw as any;
          if (raw && raw.content) {
            const value = raw.content;
            if (isReferenceValue(value)) {
              data.cell.styles.fontStyle = 'bold';
            }
          }
        }
      });

      const lastY = (doc as any).lastAutoTable.finalY;
      addCommentsToDoc(doc, comments, lastY);

      doc.save(options?.filename);

      return true;
    } catch (err) {
      return false;
    }
  }
}

/**
 * Add comments to the bottom of the table
 * @param doc   jsPDF instance
 * @param comments  Comments to add
 * @param lastY     Last Y position of the table
 */
export const addCommentsToDoc = (doc: jsPDF, comments: IndicatorNotes | undefined, lastY: number, startX: number = 0) => {
  if (comments) {

    doc.setFontSize(10);
    const maxCommentsLength = startX === 0 ? 80 : 50;
    const asPaddedText = [];
    for (const key in comments.entries) {
      const value = comments.entries[key];
      asPaddedText.push(
        ...generatePaddedText(value, 0, maxCommentsLength)
      );
    }

    // For each asPaddedText entry, add it to the document, checking
    // that it doesn't exceed the page height. If it does, add a new page
    // and continue
    let currentY = lastY + PDF_CONFIG.MARGIN_Y;
    for (const text of asPaddedText) {
      if (currentY + PDF_CONFIG.FONT_SIZE > doc.internal.pageSize.getHeight()) {
        doc.addPage();
        currentY = PDF_CONFIG.MARGIN_Y;
      }
      doc.text(text, PDF_CONFIG.MARGIN_X + startX, currentY);
      currentY += PDF_CONFIG.FONT_SIZE;
    }
  }
};

const TAB_SIZE = 4;

/**
 * For each key in the comments object, generate a string with the key and the value
 * @param comments 
 */
const generatePaddedText = (comments: IndicatorNotesEntry, current = 0, maxSize: number = 80): string[] => {
  const text: string[] = [];
  const padding = ' '.repeat(current);

  // Check if there is label and value
  if (comments.label && comments.value) {
    // Check if value is an array
    if (Array.isArray(comments.value)) {
      // If it is, add the label and render the array again
      text.push(`${padding}${comments.label}:`);
      comments.value.forEach((value) => {
        text.push(...generatePaddedText(value, current + TAB_SIZE, maxSize));
      });
    } else {
      // Simple key value pair
      text.push(`${padding}${comments.label}: ${comments.value}`);
    }
  } else {
    // Check if value is an array
    if (Array.isArray(comments.value)) {
      // If it is, render the array again
      comments.value.forEach((value) => {
        text.push(...generatePaddedText(value, current + TAB_SIZE, maxSize));
      });
    } else {

      const valueWrapped = splitTextToSize(comments.value || "", maxSize);
      // Simple value
      valueWrapped.forEach(line => text.push(`${padding}${line}`));
    }
  }

  return text;
};

const splitTextToSize = (text: string, size: number): string[] => {
  // Split the text into words
  const words = text.split(" ");
  const lines: string[] = [];
  let currentLine = "";
  for (const word of words) {
    // If the current line plus the word is longer than the max length, add the line
    // to the lines array and reset the current line
    if ((currentLine + word).length > size) {
      lines.push(currentLine);
      currentLine = "";
    }
    // Add the word to the current line
    currentLine += word + " ";
  }
  // Add the last line
  lines.push(currentLine);

  return lines;
};
