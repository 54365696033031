import { Api } from "domain/core";
import { OecdList, OecdParamList, KeyValue, IndicatorDataSource } from "domain/models/data.models";
import { argsToQuery, multiArgsToQuery } from "domain/utils";
import { BaseExportationService } from "./base-exportation.service";
import { SignUrlService } from "./signurl.service";

export class OecdService extends BaseExportationService {

  public listDatasets(): Promise<OecdList> {
    return this.fetch(Api.baseUrl + Api.data.oecd.indicators)
      .then(r => r.json());
  }

    public getParams(param: string): Promise<OecdParamList[]> {
    return this.fetch(`${Api.baseUrl}${Api.data.oecd.params}/${param}`)
      .then(r => r.json());
  }

  public getDirectDownload(measurementName: IndicatorDataSource, params: KeyValue<string | string[]>) {
    const url = `${Api.baseUrl}${Api.data.oecd.export}/${measurementName}?${multiArgsToQuery(params, '+')}`;
    return new SignUrlService().signUrl(url)
      .then(token => {
        return `${url}&token=${token}`;
    });
  }

  public getDownload(indicator: IndicatorDataSource, params: KeyValue<string>) {
    const url = `${Api.baseUrl}${Api.data.oecd.export}/${indicator.dataset}?${argsToQuery(params)}`;
    return new SignUrlService().signUrl(url)
      .then(token => {
        return `${url}&token=${token}&indicator=${indicator.indicator}`;
    });
  }

}
