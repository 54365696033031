import { CustomPalette, Palette } from 'domain/core/colors';
import { AxisDomain } from 'recharts/types/util/types';
import { CharacteristicsHealthSystems, CHSCategory } from './chs.models';
import { User, Token, Nullable } from './domain.models';
import { Variable } from './filter.model';
import { IndicatorData } from './indicator-data';
import { MenuIndicator } from './indicator.model';

/* 
  AUTH STORE
*/
export interface AuthStore {
  loggedIn: boolean;
  token?: Token;
  user?: User;
}

/*
  ROOT STORE
*/

export type NotificationVariant = 'success' | 'info' | 'warning' | 'error';

export interface Notification {
  variant: NotificationVariant;
  innerKey: number;
  timeout?: number;
  title?: string;
  className?: string;
  message?: string;
  dismissible?: boolean;
  component?: React.ReactNode;
  beforeDismiss?(): void;
}

export type RootLoadingStore =
  | { show: false }
  | {
      show: true;
      title?: string;
      message: string;
    };
export type StoreModal = JSX.Element;
export interface RootStore {
  notifications: Notification[];
  rootLoading: RootLoadingStore;
  showFixedIntro: boolean;
  modals: StoreModal[];
}

/*
  ANALYSIS STORE
*/

/* 
    COMMONS
  */
export type DefaultSettingsSelectsOption = Variable | 'INDICATOR';

/*
    TABLE SETTINGS
  */
export interface TableSettings {
  tables: DefaultSettingsSelectsOption[];
  rows: DefaultSettingsSelectsOption[];
  columns: DefaultSettingsSelectsOption[];
}

/*
    CHARTS SETTINGS
  */

export enum AvailableCharts {
  TimeSerieChart = 'TIME_SERIE',
  BarChart = 'BAR_CHART',
  ScatterChart = 'SCATTER_CHART',
}
export type ChartDomain = AxisDomain;

export enum ChartAxis {
  X = 'x',
  Y = 'y',
}

export enum ChartOrientation {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

export interface BaseChartSettings {
  domain: ChartDomain;
  chartHeight: number;
  showLegend: boolean;
  showTable: boolean;
  showComments: boolean;
}

export enum BarChartSort {
  DisagregateVariableValue = 'DISAGREGATE_VARIABLE_VALUE',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
}

export interface BarChartSettings extends BaseChartSettings {
  chartBy: DefaultSettingsSelectsOption[];
  disaggregateVariable1: DefaultSettingsSelectsOption[];
  disaggregateVariable2: DefaultSettingsSelectsOption[];
  orientation: ChartOrientation;
  order: BarChartSort;
  stackValues: boolean;
}

export interface TimeSerieChartSettings extends BaseChartSettings {
  chartBy: DefaultSettingsSelectsOption[];
  disaggregateVariable: DefaultSettingsSelectsOption[];
  movingAverage: boolean;
  regressionLine: boolean;
}

export type ScatterChartSelectsOption = Variable.Sex | Variable.Country;
export interface ScatterChartSettings
  extends Omit<BaseChartSettings, 'domain'> {
  xAxisIndicator: Nullable<MenuIndicator>;
  xAxisYear: Nullable<string>;
  yAxisIndicator: Nullable<MenuIndicator>;
  yAxisYear: Nullable<string>;
  chartBy: ScatterChartSelectsOption[];
  showBestFitLine: boolean;
  showDiagonal: boolean;
  keepAspectRatio: boolean;
  shareScales: boolean;
}

export interface ChartsSettings {
  barChart: BarChartSettings;
  timeSerie: TimeSerieChartSettings;
  scatterChart: ScatterChartSettings;
}

/*
    MAP SETTINGS
  */
export type MapSettingsSelectsOption =
  | Variable.Sex
  | Variable.Year
  | 'INDICATOR';

export enum ColorPaletteMode {
  Automatic = 'auto',
  Custom = 'custom',
}

export type AutomaticPaletteState = {
  colorPaletteMode: ColorPaletteMode.Automatic;
  colorPalette: Palette;
};
export type CustomPaletteState = {
  colorPaletteMode: ColorPaletteMode.Custom;
  colorPalette: CustomPalette;
};

export type PaletteState = AutomaticPaletteState | CustomPaletteState;

export interface MapSettings {
  chartsBy: MapSettingsSelectsOption[];
  disaggregateVariable: MapSettingsSelectsOption[];
  mapHeight: number;
  showTable: boolean;
  showComments: boolean;
  palette: PaletteState;
  noDataColor: string;
  shareLegend: boolean;
}

/*
    STORE SETTINGS
  */

export enum ErrorComponent {
  App = 'app',
  Table = 'table',
  BarChart = 'bar_chart',
  TimeSerie = 'time_serie',
  ScatterChart = 'scatter_chart',
  Map = 'map',
}

export enum ErrorSeverity {
  High,
  Medium,
  Low,
}

export type StoreCheckError = {
  component: ErrorComponent;
  code: string;
  severity: ErrorSeverity;
  allowDataLoad: boolean;
};

export interface AnalysisBranch {
  selectedIndicators: MenuIndicator[];
  selectedFilters: { [variable in Variable]: string[] };
  tableSettings: TableSettings;
  chartsSettings: ChartsSettings;
  mapSettings: MapSettings;
}

export enum Branch {
  Master = 'master',
  Draft = 'draft',
}

export interface AnalysisStore {
  [Branch.Master]: AnalysisBranch;
  [Branch.Draft]: AnalysisBranch;
  hasChanges: boolean;
  chartSelected: AvailableCharts;
  data?: IndicatorData;
  errors: StoreCheckError[];
  showErrorsModal: boolean;
  loading: boolean;
}

/*
  STATIC STORE
*/
export interface StaticStore {
  data: CharacteristicsHealthSystems;
  selectedCountry: string;
  selectedCategory: CHSCategory;
}

/*
  APP STORE
*/
export interface AppStore {
  auth: AuthStore;
  root: RootStore;
  analysis: AnalysisStore;
  static: StaticStore;
}
