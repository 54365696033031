import React, { Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';

const RootApp = React.lazy(
  () => import(/* webpackChunkName: "app.root" */ 'pages/app/root/RootApp')
);

export const Router = React.memo(() => {
  return (
    <>
      <Suspense fallback={<h1 style={{padding:'1em'}}>Cargando...</h1>}>
        <Switch>
          <Route path='/'>
            <RootApp />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
});
