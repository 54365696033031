export enum Variable {
  Sex = 'SEX',
  Year = 'YEAR',
  Country = 'COUNTRY',
}

export type FilterEntry = {
  code: string;
  variable: Variable;
  reference: boolean;
};
