import { createActionCreator } from 'deox';

import {
  AvailableCharts,
  BarChartSettings,
  ChartsSettings,
  IndicatorData,
  MapSettings,
  MenuIndicator,
  ScatterChartSettings,
  TableSettings,
  TimeSerieChartSettings,
} from 'domain/models';
import { Variable } from 'domain/models/filter.model';

export const SET_SELECTED_INDICATORS = '[Analysis] :: Set selected indicators';

export const SET_SELECTED_FITERS = '[Analysis] :: Set selected filters';

export const SET_TABLE_SETTINGS = '[Analysis] :: Set table settings';

export const SET_MAP_SETTINGS = '[Analysis] :: Set map settings';

export const SET_CHARTS_SETTINGS = '[Analysis] :: Set charts settings';

export const SET_BAR_CHART_SETTINGS = '[Analysis] :: Set bar chart settings';

export const SET_TIME_SERIE_CHART_SETTINGS =
  '[Analysis] :: Set time serie chart settings';

export const SET_SCATTER_CHART_SETTINGS =
  '[Analysis] :: Set scatter chart settings';

export const SET_ACTIVE_CHART = '[Analysis] :: Set active chart';

export const COMMIT = '[Analysis] :: Commit changes';

export const SET_DATA = '[Analysis] :: Set data';

export const SET_LOADING = '[Analysis] :: Set loading';

export const SHOW_ERRORS_MODAL = '[Analysis] :: Show errors modal';

export const setSelectedIndicators = createActionCreator(
  SET_SELECTED_INDICATORS,
  (resolve) => (indicators: MenuIndicator[]) => resolve({ indicators })
);

export const setSelectedFilters = createActionCreator(
  SET_SELECTED_FITERS,
  (resolve) => (variable: Variable, value: string[]) =>
    resolve({ variable, value })
);

export const setTableSettings = createActionCreator(
  SET_TABLE_SETTINGS,
  (resolve) => (tableSettings: TableSettings) => resolve({ tableSettings })
);

export const setMapSettings = createActionCreator(
  SET_MAP_SETTINGS,
  (resolve) => (mapSettings: MapSettings) => resolve({ mapSettings })
);

export const setChartsSettings = createActionCreator(
  SET_CHARTS_SETTINGS,
  (resolve) => (chartsSettings: ChartsSettings) => resolve({ chartsSettings })
);

export const setBarChartSettings = createActionCreator(
  SET_BAR_CHART_SETTINGS,
  (resolve) => (barChartSettings: BarChartSettings) =>
    resolve({ barChartSettings })
);

export const setTimeSerieChartSettings = createActionCreator(
  SET_TIME_SERIE_CHART_SETTINGS,
  (resolve) => (timeSerieChartSettings: TimeSerieChartSettings) =>
    resolve({ timeSerieChartSettings })
);

export const setScatterChartSettings = createActionCreator(
  SET_SCATTER_CHART_SETTINGS,
  (resolve) => (scatterChartSettings: ScatterChartSettings) =>
    resolve({ scatterChartSettings })
);

export const setActiveChart = createActionCreator(
  SET_ACTIVE_CHART,
  (resolve) => (activeChart: AvailableCharts) => resolve({ activeChart })
);

export const commit = createActionCreator(
  COMMIT,
  (resolve) => (ignoreChecks?: boolean) => resolve(ignoreChecks)
);

export const setData = createActionCreator(
  SET_DATA,
  (resolve) => (data: IndicatorData) => resolve({ data })
);

export const setLoading = createActionCreator(
  SET_LOADING,
  (resolve) => (loading: boolean) => resolve(loading)
);

export const setShowErrorsModal = createActionCreator(
  SHOW_ERRORS_MODAL,
  (resolve) => (show: boolean) => resolve(show)
);
