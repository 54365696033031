import { createStore, combineReducers } from 'redux';

import { AppStore } from 'domain/models';

import { authReducer } from './auth';
import { rootReducer } from './root';
import { analysisReducer } from './analysis';
import { staticReducer } from './static';

const appReducer = combineReducers<AppStore>({
  auth: authReducer,
  root: rootReducer,
  analysis: analysisReducer,
  static: staticReducer,
});

export default createStore(appReducer);
