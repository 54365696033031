import { createActionCreator } from 'deox';

import { Notification, RootLoadingStore, StoreModal } from 'domain/models';

export const PUSH_NOTIFICATION = '[Root] :: Add notification';
export const REMOVE_NOTIFICATION = '[Root] :: Remove Notification';

export const SHOW_ROOT_LOADING = '[Root] :: Show root loading';
export const HIDE_ROOT_LOADING = '[Root] :: Hide root loading';

export const SHOW_FIXED_INTRO = '[Root] :: Show fixed intro';
export const HIDE_FIXED_INTRO = '[Root] :: Hide fixed intro';

export const PUSH_MODAL = '[Root] :: Push modal';
export const POP_MODAL = '[Root] :: Pop modal';

export const pushNotification = createActionCreator(
  PUSH_NOTIFICATION,
  (resolve) => (notification: Omit<Notification, 'innerKey'>) =>
    resolve(notification)
);

export const removeNotification = createActionCreator(
  REMOVE_NOTIFICATION,
  (resolve) => (key: number) => resolve(key)
);

export const showRootLoading = createActionCreator(
  SHOW_ROOT_LOADING,
  (resolve) => (payload: RootLoadingStore) => resolve(payload)
);

export const hideRootLoading = createActionCreator(
  HIDE_ROOT_LOADING,
  (resolve) => () => resolve()
);

export const setShowFixedIntro = createActionCreator(
  SHOW_FIXED_INTRO,
  (resolve) => () => resolve()
);

export const setHideFixedIntro = createActionCreator(
  HIDE_FIXED_INTRO,
  (resolve) => () => resolve()
);

export const pushModal = createActionCreator(
  PUSH_MODAL,
  (resolve) => (modal: StoreModal) => resolve({ modal })
);

export const popModal = createActionCreator(
  POP_MODAL,
  (resolve) => () => resolve()
);
