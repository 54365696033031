/**
 * Estos hooks se utilizan para obtener las opciones para los selectores
 * de configuración de las tablas, gráficas y mapas
 *
 * Lo saco a un hook para poder filtrar estas opciones en función de los
 * filtros que admita cada uno de los indicadores seleccionados sin
 * tener que añadir estos indicadores dentro de cada componente que lo
 * usa
 */

import {
  AnalysisBranch,
  AppStore,
  DefaultSettingsSelectsOption,
  MenuIndicator,
  Variable,
} from 'domain/models';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Country: OptionFn = (t: TFunction): Option => ({
  label: t('common:variables.COUNTRY'),
  value: Variable.Country,
  isDisabled: false,
  isSelected: false,
});

const Sex: OptionFn = (t: TFunction): Option => ({
  label: t('common:variables.SEX'),
  value: Variable.Sex,
  isDisabled: false,
  isSelected: false,
});

const Year: OptionFn = (t: TFunction): Option => ({
  label: t('common:variables.YEAR'),
  value: Variable.Year,
  isDisabled: false,
  isSelected: false,
});

const Indicator: OptionFn = (t: TFunction): Option => ({
  label: t('common:variables.INDICATOR'),
  value: 'INDICATOR',
  isDisabled: false,
  isSelected: false,
});

export type Option = {
  label: string;
  value: DefaultSettingsSelectsOption;
  isDisabled: boolean;
  isSelected: boolean;
};

type OptionFn = (t: TFunction) => Option;
type IOptions = { [K in Variable]: OptionFn };
type OptionsWithIndicator = { [K in DefaultSettingsSelectsOption]: OptionFn };
export type OptionsKeys = (keyof IOptions | 'INDICATOR') | '*';

const Options: IOptions = {
  [Variable.Country]: Country,
  [Variable.Sex]: Sex,
  [Variable.Year]: Year,
};

export enum Viz {
  Table,
  TimeSerie,
  BarChart,
  ScatterChart,
  Map,
}

export const getRealSelectedIndicators = (
  viz: Viz,
  draft: AnalysisBranch
): MenuIndicator[] => {
  switch (viz) {
    case Viz.Table:
      return draft.selectedIndicators;
    case Viz.TimeSerie:
      return draft.selectedIndicators;
    case Viz.BarChart:
      return draft.selectedIndicators;
    case Viz.ScatterChart:
      const { xAxisIndicator, yAxisIndicator } =
        draft.chartsSettings.scatterChart;
      if (xAxisIndicator && yAxisIndicator) {
        if (xAxisIndicator.code !== yAxisIndicator.code) {
          return [xAxisIndicator, yAxisIndicator];
        } else {
          return [xAxisIndicator];
        }
      }
      return draft.selectedIndicators;
    case Viz.Map:
      return draft.selectedIndicators;
    default:
      return draft.selectedIndicators;
  }
};

export const useSelectsOptions = (
  viz: Viz,
  ...pick: OptionsKeys[]
): Option[] => {
  const { t } = useTranslation('common');

  const draft = useSelector((store: AppStore) => store.analysis.draft);

  return React.useMemo(() => {
    const indicators = getRealSelectedIndicators(viz, draft);
    const filtered = filterOptions(pick, indicators);
    return Object.values(filtered)
      .filter((fn) => !!fn)
      .map((fn) => fn!(t));
  }, [t, draft, pick, viz]);
};

export const filterOptions = (
  filter: OptionsKeys[],
  selectedIndicators: MenuIndicator[]
): Partial<OptionsWithIndicator> => {
  let filtered: Partial<OptionsWithIndicator> = {};
  if (filter.includes('*')) {
    filtered = Options;
  } else {
    filtered = Object.entries(Options)
      .filter(([key, fn]) => {
        return filter.includes(key as OptionsKeys);
      })
      .reduce((acc: Partial<OptionsWithIndicator>, [key, fn]) => {
        return {
          ...acc,
          [key]: fn,
        };
      }, {});
  }

  if (selectedIndicators.length > 0) {
    const commonKeys: Variable[] = [
      Variable.Country,
      Variable.Sex,
      Variable.Year,
    ];
    let newCommonKeys: Variable[] = [];

    for (let indicator of selectedIndicators) {
      for (let key of commonKeys) {
        if (indicator.variables.includes(key) && !newCommonKeys.includes(key)) {
          newCommonKeys = newCommonKeys.concat(key);
        }
      }
    }

    filtered = Object.entries(filtered)
      .filter(([key, opt]: [any, OptionFn | undefined]) => {
        return newCommonKeys.includes(key);
      })
      .reduce((acc: Partial<OptionsWithIndicator>, [key, fn]) => {
        return {
          ...acc,
          [key]: fn,
        };
      }, {});
  }

  if (filter.includes('*') || filter.includes('INDICATOR')) {
    filtered.INDICATOR = Indicator;
  }

  return filtered;
};
