import { Api } from "domain/core";
import { DumpEdit, IndicatorDataSource } from "domain/models";
import { AuthorizedService } from "../authorized-service.base";
import { SignUrlService } from "./signurl.service";

export class AdminDataService extends AuthorizedService {
  
  public getExportDump(dumpId: number): Promise<string> {
    const url = `${Api.baseUrl}${Api.admin.dumps}/${dumpId}/export`;
    return new SignUrlService().signUrl(url)
      .then(token => {
        return `${url}?token=${token}`;
    });
  }

  public uploadData({ indicator, comments, file }: { indicator: string, comments: string, file: File }) {
    const url = `${Api.baseUrl}${Api.admin.import}/${indicator}`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('comments', comments);
    return this.fetchRaw(url, {
      method: 'POST',
      body: formData,
    });
  }

  public saveDump(dumpId: number, dump: DumpEdit) {
    const url = `${Api.baseUrl}${Api.admin.dumps}/${dumpId}`;
    return this.fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(dump),
    });
  }

  public deleteDump(dumpId: number) {
    const url = `${Api.baseUrl}${Api.admin.dumps}/${dumpId}`;
    return this.fetch(url, {
      method: 'DELETE',
    });
  }

  public acceptDump(dumpId: number) {
    const url = `${Api.baseUrl}${Api.admin.dumps}/${dumpId}/accept`;
    return this.fetchRaw(url, {
      method: 'PUT',
    });
  }

  public createIndicatorDataSource(data: IndicatorDataSource): Promise<Response> {

    const url = `${Api.baseUrl}${Api.admin.sources}`;
    return this.fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public updateIndicatorDataSource(id: string, data: IndicatorDataSource): Promise<Response> {

    const url = `${Api.baseUrl}${Api.admin.sources}/${id}`;
    return this.fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }

  public deleteIndicatorDataSource(id: string) {
    const url = `${Api.baseUrl}${Api.admin.sources}/${id}`;
    return this.fetch(url, {
      method: 'DELETE',
    });
  }

}

export const adminDataService = new AdminDataService();
