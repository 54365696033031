import { useTranslation } from 'react-i18next';

export interface NumberFormatConfig {
  fixed?: number;
}

const DefaultNumberFormat: NumberFormatConfig = {
  fixed: 2,
};

export class NumberFormatUtils {
  static formatters: { [locale: string]: Intl.NumberFormat } = {};

  static getSeparator(): string {
    const testNumber = 1.1;
    return testNumber.toLocaleString().substring(1, 2);
  }
}

export const useNumberFormatter = (
  config: NumberFormatConfig = DefaultNumberFormat
) => {
  const { i18n } = useTranslation();
  const formatter = getNumberFormatter(i18n.language, config);
  return formatter;
};

export const getNumberFormatter = (
  language: string,
  config: NumberFormatConfig = DefaultNumberFormat
) => {
  const formatter = new Intl.NumberFormat(language, {
    minimumFractionDigits: config?.fixed,
  });
  return formatter.format;
};
