// Esto se hace para poder mockear las funciones que se llaman dentro de otras funciones
// que están en el mismo módulo.
// @see https://stackoverflow.com/a/55193363
import { RestQueryExtras } from 'domain/models';
import { CancellablePromise } from 'domain/models/common.models';
import * as CommonUtils from '.';

export const range = (length: number, from: number = 0): number[] =>
  Array.from(new Array(length), (_, i) => from + i);

export const clamp = (target: number, min: number, max: number) =>
  Math.min(Math.max(target, min), max);

export const assert = (
  condition: boolean,
  message: string = 'Assertion failed'
): void => {
  if (!condition) {
    throw new Error(message);
  }
};

export const mergeRefs = (...refs: any[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const downloadBlob = (filename: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  CommonUtils.triggerLinkClick(url, filename);
};

export const urlToBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  if (response.body?.constructor.name === 'Blob') {
    return await response.blob();
  }

  if (response.body?.constructor.name === 'ReadableStream') {
    const stream: ArrayBuffer = await response.arrayBuffer();
    return new Blob([new Uint8Array(stream, 0, stream.byteLength)]);
  }

  const text = await response.text();
  return new Blob([text]);
};


// Keep the order of the options from tables, columns, rows
export const doSortOptions = <T extends { value: any },>(current: T[], sel: T[]) => {
  return current.sort((a, b) => {
    const aIndex = sel.indexOf(a.value),
      bIndex = sel.indexOf(b.value);
    return aIndex - bIndex;
  });
};

export const triggerLinkClick = (url: string, filename?: string): void => {
  const a = document.createElement('a');
  a.href = url;
  if (filename) {
    a.download = `${filename}`;
  }
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const arrayMoveMutate = <T>(
  array: T[],
  from: number,
  to: number
): void => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = <T>(array: T[], from: number, to: number): T[] => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

export const toPaginationQuery = ({
  page,
  pageSize,
  sort,
  sortDirection,
  ...extras
}: RestQueryExtras): string => {
  return `?page=${page}&size=${pageSize}&sort=${sort},${sortDirection}${Object.entries(
    extras
  ).reduce((acc: string, [k, v]: [string, any]) => `${acc}&${k}=${v}`, '')}`;
};

export const argsToQuery = <T extends object>(args?: T): string => {
  return args
    ? Object.entries(args)
      .filter(
        ([key, value]): boolean => value !== undefined && value !== null
      )
      .reduce((acc: string, [key, value]): string => {
        return `${acc}&${key}=${value}`;
      }, '')
    : '';
};

export const arrayArgsToQuery = <T extends object>(args: T): string => {
  return arrayArgsToQueryMapper(args, (key: string, value: string[]) =>
    value.map((v: string) => `${key}=${v}`).join('&')
  );
};

export const multiArgsToQuery = <T extends object>(
  args: T,
  joinString: string = '+'
): string => {
  return arrayArgsToQueryMapper(
    args,
    (key: string, value: string[]) => `${key}=${value.join(joinString)}`
  );
};

export const arrayArgsToQueryMapper = <T extends object>(
  args: T,
  mergeFn: Function
): string => {
  return args
    ? Object.entries(args)
      .filter(
        ([key, value]): boolean => value !== undefined && value !== null
      )
      .reduce((acc: string, [key, value]): string => {
        if (value.constructor.name === 'Array') {
          const multiKeyValue = mergeFn(key, value);
          return `${acc}&${multiKeyValue}`;
        }
        return `${acc}&${key}=${value}`;
      }, '')
    : '';
};

export const deepCompareObjects = <T extends object>(o1: T, o2: T) => {
  return JSON.stringify(o1) === JSON.stringify(o2);
};

export const cancellablePromise = <T>(
  promise: Promise<T>
): CancellablePromise<T> => {
  let isCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error })
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

export const delay = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

export const asSelect = (list: string[], labelMapper?: Function) => {
  const labelMap = (v: string) => (labelMapper ? labelMapper(v) : v);
  return list.map((v) => ({ label: labelMap(v), value: v }));
};

export const strContains = (
  target: string,
  crit: string,
  ignoreCase = true
): boolean => {
  if (ignoreCase) {
    return target.toLocaleLowerCase().includes(crit.toLocaleLowerCase());
  } else {
    return target.includes(crit);
  }
};

export const highlightElements = (
  selector: string,
  duration: number = 3000,
  className: string = 'highlight'
) => {
  if (!selector) {
    return;
  }

  const elements = document.querySelectorAll(selector);

  const triggerTimeout = (el: Element) => {
    let timeout = setTimeout(() => {
      if (el.classList.contains(className)) {
        el.classList.remove(className);
        clearTimeout(timeout);
      }
    }, duration);
  };

  for (let el of elements) {
    if (!el.classList.contains(className)) {
      el.classList.add(className);
      triggerTimeout(el);
    }
  }
};

export const getStringWidth = (text: string, font: string) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx!.font = font;
  const metrics = ctx?.measureText(text);
  return metrics!.width;
};
