import { Colors } from 'domain/core/colors';
import { getIsMobile } from 'domain/hooks/useIsMobile';
import {
  BarChartSettings,
  BarChartSort,
  ChartOrientation,
  TimeSerieChartSettings,
  AnalysisBranch,
  ColorPaletteMode,
  Variable,
  ScatterChartSettings,
} from 'domain/models';

const chartHeight = getIsMobile() ? window.innerWidth - 20 : 600;

export const defaultFilters: AnalysisBranch['selectedFilters'] = {
  [Variable.Country]: [],
  [Variable.Sex]: [],
  [Variable.Year]: [],
};

export const defaultBarChartSettings: BarChartSettings = {
  chartBy: ['INDICATOR', Variable.Sex],
  disaggregateVariable1: [Variable.Country],
  disaggregateVariable2: [Variable.Year],
  chartHeight: chartHeight,
  domain: ['dataMin', 'dataMax'],
  order: BarChartSort.DisagregateVariableValue,
  orientation: ChartOrientation.Vertical,
  showLegend: true,
  stackValues: false,
  showTable: false,
  showComments: true,
};

export const defaultTimeSerieSettings: TimeSerieChartSettings = {
  chartBy: ['INDICATOR', Variable.Sex],
  disaggregateVariable: [Variable.Country],
  chartHeight: chartHeight,
  domain: ['dataMin', 'dataMax'],
  movingAverage: false,
  regressionLine: false,
  showLegend: true,
  showTable: false,
  showComments: true,
};

export const defaultScatterChartSettings: ScatterChartSettings = {
  chartBy: [Variable.Sex],
  chartHeight: chartHeight,
  showLegend: true,
  showTable: false,
  xAxisIndicator: null,
  xAxisYear: null,
  yAxisIndicator: null,
  yAxisYear: null,
  showBestFitLine: false,
  showDiagonal: true,
  keepAspectRatio: true,
  showComments: true,
  shareScales: false,
};

export const defaultBranch: AnalysisBranch = {
  selectedFilters: defaultFilters,
  selectedIndicators: [],
  chartsSettings: {
    barChart: defaultBarChartSettings,
    timeSerie: defaultTimeSerieSettings,
    scatterChart: defaultScatterChartSettings,
  },
  mapSettings: {
    chartsBy: [Variable.Sex, Variable.Year, 'INDICATOR'],
    disaggregateVariable: [],
    mapHeight: chartHeight,
    showTable: false,
    showComments: true,
    shareLegend: true,
    palette: {
      colorPaletteMode: ColorPaletteMode.Automatic,
      colorPalette: Colors.Primary.getPalette(),
    },
    noDataColor: Colors.WhiteToBlack.getPalette()[3],
  },
  tableSettings: {
    tables: ['INDICATOR'],
    rows: [Variable.Country],
    columns: [Variable.Sex, Variable.Year],
  },
};
