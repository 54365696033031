import { Api } from 'domain/core';
import {
  AnalysisBranch,
  DiseaseReportData,
  IndicatorData,
  IndicatorNotes,
  IndicatorTableData,
  RestQueryExtras,
  RestSortInfo,
} from 'domain/models';
import { toPaginationQuery } from 'domain/utils';
import {
  FilterOrIndicatorKeys,
  MultiFilterOrIndicator,
} from 'domain/utils/controllers';
import { getZoneOffset } from 'domain/utils/date.utils';

export class AnalysisDataService {
  public checkParameters(master: AnalysisBranch): boolean {
    return master.selectedIndicators.length > 0;
  }

  public static async loadDiseaseReportData(
    indicator: string,
    lang: string
  ): Promise<DiseaseReportData> {

    try {

      const r = await fetch(
        `${Api.baseUrl}${Api.data.reports.disease}/${indicator}?&lang=${lang}&tz=${getZoneOffset()}`,
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: 'Basic cHJlZGljdGlhOmRlbW8=',
          },
        }
      );
      const data = await r.json();
      return data as DiseaseReportData;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public loadData(
    master: AnalysisBranch,
    lang: string
  ): Promise<IndicatorData> {
    const params: RestQueryExtras = {
      indicatorCode: master.selectedIndicators.map((i) => i.code),
      sexId: master.selectedFilters.SEX,
      yearId: master.selectedFilters.YEAR,
      countryId: master.selectedFilters.COUNTRY,
      page: 0,
      pageSize: 10000,
      sort: '',
      sortDirection: 'ASC',
    };

    return fetch(
      `${Api.baseUrl}${Api.analysis.data}${toPaginationQuery(
        params
      )}&lang=${lang}&tz=${getZoneOffset()}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Basic cHJlZGljdGlhOmRlbW8=',
        },
      }
    )
      .then((r) => r.json())
      .then((data) => data as IndicatorData);
  }

  public loadNotes(
    master: AnalysisBranch,
    selectedGraphic: MultiFilterOrIndicator,
    lang: string,
    sortInfo?: RestSortInfo
  ): Promise<IndicatorNotes> {
    const params = this.getParamsBySelectedGraphic(
      master,
      selectedGraphic,
      sortInfo
    );

    return fetch(
      `${Api.baseUrl}${Api.analysis.notes}${toPaginationQuery(
        params
      )}&lang=${lang}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Basic cHJlZGljdGlhOmRlbW8=',
        },
      }
    )
      .then((r) => r.json())
      .then((data) => data as IndicatorNotes);
  }

  public loadTableData(
    master: AnalysisBranch,
    selectedGraphic: MultiFilterOrIndicator,
    lang: string,
    sortInfo?: RestSortInfo
  ): Promise<IndicatorTableData> {
    const params = this.getParamsBySelectedGraphic(
      master,
      selectedGraphic,
      sortInfo
    );

    return fetch(
      `${Api.baseUrl}${Api.analysis.table}${toPaginationQuery(
        params
      )}&lang=${lang}&tz=${getZoneOffset()}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Basic cHJlZGljdGlhOmRlbW8=',
        },
      }
    )
      .then((r) => r.json())
      .then((data) => data as IndicatorTableData);
  }

  public exportXLSURL(
    master: AnalysisBranch,
    selectedGraphic: MultiFilterOrIndicator,
    sortInfo?: RestSortInfo,
    options?: any
  ): string {
    const params = {
      ...this.getParamsBySelectedGraphic(master, selectedGraphic, sortInfo),
      lang: options?.lang,
      tz: getZoneOffset(),
      filename: options?.filename,
      title: options?.title,
    };
    return `${Api.baseUrl}${Api.analysis.tableXLS}${toPaginationQuery(params)}`;
  }

  private getParamsBySelectedGraphic(
    master: AnalysisBranch,
    selectedGraphic: MultiFilterOrIndicator,
    sortInfo?: RestSortInfo
  ): RestQueryExtras {
    let params: RestQueryExtras = {
      indicatorCode: master.selectedIndicators.map((i) => i.code),
      sexId: master.selectedFilters.SEX,
      yearId: master.selectedFilters.YEAR,
      countryId: master.selectedFilters.COUNTRY,
      rows: master.tableSettings.rows,
      columns: master.tableSettings.columns,
      page: 0,
      pageSize: 10000,
      sort: sortInfo?.sort || '',
      sortDirection: sortInfo?.sortDirection || 'ASC',
    };

    for (let opt of selectedGraphic.options) {
      const paramsKey = this.optionCodeToRestParam(opt.option);
      if (paramsKey) {
        params = {
          ...params,
          [paramsKey]: opt.value,
        };
      }
    }

    return params;
  }

  private optionCodeToRestParam(
    optCode: FilterOrIndicatorKeys
  ): 'sexId' | 'yearId' | 'countryId' | 'indicatorCode' {
    switch (optCode) {
      case 'country':
        return 'countryId';
      case 'indicator':
        return 'indicatorCode';
      case 'sex':
        return 'sexId';
      case 'year':
        return 'yearId';
    }
  }
}
