/**
 * Checks if the given value is a reference value:
 * - Unión Europea
 * - European Union
 * - España
 * - Spain
 */

const REFERENCE_VALUES = [
  "Unión Europea",
  "European Union",
  "España",
  "Spain",
];

const REFERENCE_CODES = [
  "UE",
  "EU_28",
  "ES",
  "EU-27_2020",
  "EU_MEMBERS"
];

export const isReferenceValue = (value: string): boolean => {
  return REFERENCE_VALUES.includes(value);
}

export const isReferenceCode = (code: string): boolean => {
  return REFERENCE_CODES.includes(code);
}
