import { createReducer } from 'deox';

import * as Actions from './root.actions';

import { RootStore, Notification, RootLoadingStore } from 'domain/models';

export const initialState: RootStore = {
  notifications: [],
  rootLoading: {
    show: false,
  },
  showFixedIntro: false,
  modals: [],
};

export const rootReducer = createReducer(initialState, (handle) => [
  handle(Actions.pushNotification, (state, { payload }) => ({
    ...state,
    notifications: state.notifications.concat(
      Object.freeze({ ...payload, innerKey: Date.now() })
    ),
  })),
  handle(Actions.removeNotification, (state, { payload }) => ({
    ...state,
    notifications: state.notifications.filter((n: Notification) => {
      return n.innerKey !== payload;
    }),
  })),
  handle(Actions.showRootLoading, (state, { payload }) => ({
    ...state,
    rootLoading: payload,
  })),
  handle(Actions.hideRootLoading, (state) => ({
    ...state,
    rootLoading: {
      show: false,
    } as RootLoadingStore,
  })),
  handle(Actions.setShowFixedIntro, (state) => ({
    ...state,
    showFixedIntro: true,
  })),
  handle(Actions.setHideFixedIntro, (state) => ({
    ...state,
    showFixedIntro: false,
  })),
  handle(Actions.pushModal, (state, { payload }) => ({
    ...state,
    modals: state.modals.concat(payload.modal),
  })),
  handle(Actions.popModal, (state) => ({
    ...state,
    modals: state.modals.slice(1, state.modals.length),
  })),
]);
