import { isValid } from 'domain/utils';
import { AuthorizationService } from 'domain/services';

import { Token } from 'domain/models';

export abstract class AuthorizedService {

  protected async fetch(
    input: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response> {
    
    const validToken = await this.getValidToken();
    return this.delegate(input, init, validToken);
    // If fetch returns 403/401 try to refresh, if fails redirect to /auth
  }
  
  protected async fetchRaw(
    input: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response> {
    
    const validToken = await this.getValidToken();
    return this.delegateRaw(input, init, validToken);
    // If fetch returns 403/401 try to refresh, if fails redirect to /auth
  }

  

  async getValidToken(): Promise<Token> {
    const token = AuthorizationService.getAuthorization();
    const [valid, remaining] = isValid(token);
    if (valid && remaining > 1000 * 300) {
      // 5 Minutes
      return token as Token;
    } else {
      const newToken = await new AuthorizationService().renew();
      return newToken;
    }
  }

  private delegate(
    input: RequestInfo,
    init: RequestInit | undefined = { headers: {} },
    token: Token
  ): Promise<Response> {
    return fetch(input, {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        ...init.headers,
        ...this.buildAuthorizationHeader(token)
      }
    });
  }

  private delegateRaw(
    input: RequestInfo,
    init: RequestInit | undefined = { headers: {} },
    token: Token
  ): Promise<Response> {
    return fetch(input, {
      ...init,
      headers: {
        ...init.headers,
        ...this.buildAuthorizationHeader(token)
      }
    });
  }

  private buildAuthorizationHeader(token: Token): { Authorization: string } {
    return { Authorization: `Bearer  ${token.token}` };
  }
}

export class AuthorizationServiceImpl extends AuthorizedService {

  public fetch(input: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
    return super.fetch(input, init);
  }
}

export const authorizationService = new AuthorizationServiceImpl();
