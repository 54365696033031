import { Api } from "domain/core";
import { AuthorizedService } from "../authorized-service.base";

export class SignUrlService extends AuthorizedService {

  public signUrl(url: string) {
    return this.fetch(`${Api.baseUrl}${Api.data.signUrl}?url=${url}`)
    .then(r => r.json())
    .then(({ token }: any) => {
      return token;
    });
  }
  
}