import { createActionCreator } from 'deox';
import { Token, User } from 'domain/models';

export const LOGIN_SUCCESS = '[Auth] :: Login success';

export const SET_PROFILE = '[Auth] :: Set Current User Profile';

export const LOGOUT = '[Auth] :: Register';

export const REFRESH_SUCCESS = '[Auth] :: Refresh session success';

export const loginSuccess = createActionCreator(
  LOGIN_SUCCESS,
  resolve => ( token: Token ) => resolve(token)
);

export const logout = createActionCreator(LOGOUT, resolve => () => resolve());

export const refreshSuccess = createActionCreator(
  REFRESH_SUCCESS,
  resolve => (response: Token) => resolve(response)
);

export const setProfile = createActionCreator(
  SET_PROFILE,
  resolve => (profile: User) => resolve(profile)
);
