import {
  CATEGORIES,
  CharacteristicsHealthSystems,
  CHSCategory,
  HealthSystem,
  HealthSystemModel,
} from 'domain/models/chs.models';
import { KeyValue } from 'domain/models/common.models';
import html2canvas from 'html2canvas';
import { TFunction } from 'i18next';
import jsPDF from 'jspdf';
import { categoriesConfig } from 'pages/app/static/categoryDataConfig';

export class StaticController {
  static PdfExporter = class {
    private PRIMARY_COLOR = '#055f7c';
    private SECONDARY_COLOR = '#888888';

    private catSpacing: { [cat in CHSCategory]: number } = {
      health_system: 12,
      health_care: 10,
      'patient_co-payment': 12,
      remuneration_of_physician: 20,
    };

    private y = 0;

    constructor(
      private data: CharacteristicsHealthSystems,
      private svgMap: SVGElement,
      private lang: string,
      private t: TFunction
    ) { }

    public async exportReportToPdfForCategory(category: CHSCategory) {
      const doc = new jsPDF('p', 'px', 'a4', true);
      const { width } = doc.internal.pageSize;

      const cat = this.t(`static:categories.${category}.label`);

      this.setTextDefinition('title', doc);
      doc.text(cat, width / 2, 100, { align: 'center', maxWidth: width - 60 });
      this.y = 100;

      await this.printMapPng(doc);
      doc.addPage();
      this.y = 0;

      if (category === CHSCategory.HealthSystem) {
        this.printModelsInfo(doc);
        doc.addPage();
        this.y = 0;
      }

      this.printCountriesInfo(doc, category);

      return doc.save(cat);
    }

    public async exportReportToPdfForCountry(country: string) {

      const doc = new jsPDF('p', 'px', 'a4', true);
      const { width } = doc.internal.pageSize;

      const tCountry = this.t(`variables:country.${country}`);

      this.setTextDefinition('title', doc);
      doc.text(tCountry, width / 2, 100, {
        align: 'center',
        maxWidth: width - 60,
      });
      this.y = 100;

      await this.printMapPng(doc);
      doc.addPage();
      this.y = 0;

      const countryData = this.data.countries.find(
        (c) => c.country === country
      );

      if (!countryData) {
        throw new Error(`Country ${country} not found`);
      }

      const countryModel = this.data.models.find(
        (m) => m.name === countryData.model[this.lang]
      );

      if (!countryModel) {
        throw new Error(`Model ${countryData.model[this.lang]} not found`);
      }

      this.printModelInfo(doc, countryModel);
      this.printCountryInfo(doc, countryData);

      return doc.save(tCountry);
    }

    private printCountryInfo(doc: jsPDF, countryData: HealthSystem) {
      const { width } = doc.internal.pageSize;

      for (let i = 0; i < CATEGORIES.length; i++) {
        const cat = CATEGORIES[i];
        const catConfig = categoriesConfig[cat];

        if (i === 2) {
          doc.addPage();
          this.y = 20;
        }

        const tCategory = this.t(`static:categories.${cat}.label`);
        this.setTextDefinition('subtitle', doc);

        this.setTextDefinition('subtitle', doc);
        doc.text(tCategory, 20, this.y + 30);
        this.y += 30;

        doc.setDrawColor(this.PRIMARY_COLOR);
        doc.line(20, this.y + 5, width / 2, this.y + 5, 'DF');

        this.y += 10;

        for (let j = 0; j < catConfig.length; j++) {
          const rawKey = catConfig[j];
          let value = (countryData[rawKey.key] as KeyValue<string>)[this.lang];

          // Check if value has <sup>*</sup>, if so, keep * and get the rest for
          // the note (in a new line)
          const note = value.match(/<sup>\*<\/sup>(.*)/);
          let noteText = '';
          if (note) {
            value = value.replace(note[0], '') + '*';
            noteText = note[1];

            // Replace any html tag with a space
            noteText = noteText.replace(/<[^>]*>?/gm, ' ');
          }

          const tKey = this.t(`static:keys.${rawKey.key}.label`) + ':';

          const text = `${tKey}  ${value}`;

          this.y += this.catSpacing[cat];

          doc.setDrawColor(this.PRIMARY_COLOR);
          doc.setFillColor(this.PRIMARY_COLOR);
          doc.circle(26, this.y - 3, 1, 'DF');

          this.setTextDefinition('text', doc);
          const keyLines = doc.splitTextToSize(text, width - 60);
          doc.text(keyLines, 30, this.y, {
            align: 'left',
            maxWidth: width - 60,
          });

          this.y += keyLines.length * 8;

          // Print note, if any
          if (noteText) {
            // Clean note text from non-ascii characters, except ñ
            // Print each characte and check if it's ascii
            // If it's not, replace it with a space
            // We have to do this because I am not able to use
            // regex to replace non-ascii characters and keep ñ
            for (let k = 0; k < noteText.length; k++) {
              const char = noteText[k];
              if (char.charCodeAt(0) > 127 && char !== 'ñ') {
                noteText = noteText.replace(char, '');
              }
            }

            doc.setFontSize(9);
            doc.setTextColor(this.SECONDARY_COLOR);
            doc.text(noteText.trim(), 30, this.y + 7, { align: 'left' });
            doc.setFontSize(11);
            doc.setTextColor(this.PRIMARY_COLOR);
            this.y += 10;
          }
        }
      }
    }

    private printModelsInfo(doc: jsPDF) {
      const title = this.t('static:model.pdfTitle');
      const width = doc.internal.pageSize.width;

      this.setTextDefinition('subtitle', doc);
      doc.text(title, width / 2, 30, { align: 'center', maxWidth: width - 60 });
      this.y = 30;
      for (let i = 0; i < this.data.models.length; i++) {
        const model = this.data.models[i];
        this.printModelInfo(doc, model);
      }
    }

    private printModelInfo(doc: jsPDF, model: HealthSystemModel) {
      const { width } = doc.internal.pageSize;

      const modelName = `${model.type[this.lang]} (${model.name})`;

      this.setTextDefinition('subtitle', doc);
      doc.text(modelName, 20, this.y + 40);
      this.y += 50;

      for (let j = 0; j < model.characteristics[this.lang].length; j++) {
        const ch = model.characteristics[this.lang][j];

        doc.setDrawColor(this.PRIMARY_COLOR);
        doc.setFillColor(this.PRIMARY_COLOR);
        doc.circle(26, this.y + 6, 1, 'DF');

        this.setTextDefinition('text', doc);
        const lines = doc.splitTextToSize(ch, width - 60);
        doc.text(lines, 30, this.y + 10, {
          align: 'left',
          maxWidth: width - 60,
        });
        this.y += 10 + lines.length * 10;
      }
    }

    private async printMapPng(doc: jsPDF) {
      const { width } = doc.internal.pageSize;
      const el = document.getElementById('static_map');
      if (el) {
        const mapCanvas = await html2canvas(el as any, {
          ignoreElements: (element: Element) => {
            return element.classList.contains('legend');
          },
        });
        const left = (width - mapCanvas.width / 2) / 2;
        doc.addImage(
          mapCanvas,
          'JPEG',
          left,
          this.y + 80,
          mapCanvas.width / 2,
          mapCanvas.height / 2
        );
      }
    }

    private printCountriesInfo(doc: jsPDF, category: CHSCategory) {
      const catConfig = categoriesConfig[category];
      const { width } = doc.internal.pageSize;

      let _y = 0;
      const elPerPage = this.getElementsPerPageByCategory(category);

      for (let i = 0; i < this.data.countries.length; i++) {
        const countryData = this.data.countries[i];

        const tCountry = this.t(`variables:country.${countryData.country}`);

        if (category !== CHSCategory.HealthCare && i % elPerPage !== 0) {
          _y += 10;
        }

        this.setTextDefinition('subtitle', doc);
        doc.text(tCountry, 20, _y + 30);
        _y += 30;

        doc.setDrawColor(this.PRIMARY_COLOR);
        doc.line(20, _y + 5, width / 2, _y + 5, 'DF');

        _y += 10;

        for (let j = 0; j < catConfig.length; j++) {
          const rawKey = catConfig[j];
          let value = (countryData[rawKey.key] as KeyValue<string>)[this.lang];

          value = value
            .replace("<sup>*</sup><br/><span class='note'>*", '.')
            .replace('</span>', '');

          // Check if value has <sup>*</sup>, if so, keep * and get the rest for
          // the note (in a new line)
          const note = value.match(/<sup>\*<\/sup>(.*)/);
          let noteText = '';
          if (note) {
            value = value.replace(note[0], '') + '*';
            noteText = note[1];

            // Replace any html tag with a space
            noteText = noteText.replace(/<[^>]*>?/gm, ' ');
          }

          const tKey = this.t(`static:keys.${rawKey.key}.label`) + ':';

          const text = `${tKey}  ${value}`;

          _y += this.catSpacing[category];

          doc.setDrawColor(this.PRIMARY_COLOR);
          doc.setFillColor(this.PRIMARY_COLOR);
          doc.circle(26, _y - 3, 1, 'DF');

          this.setTextDefinition('text', doc);
          const keyLines = doc.splitTextToSize(text, width - 60);
          doc.text(keyLines, 30, _y, { align: 'left', maxWidth: width - 60 });

          _y += keyLines.length * 8;

          // Print note, if any
          if (noteText) {
            // Clean note text from non-ascii characters, except ñ
            // Print each characte and check if it's ascii
            // If it's not, replace it with a space
            // We have to do this because I am not able to use
            // regex to replace non-ascii characters and keep ñ
            for (let k = 0; k < noteText.length; k++) {
              const char = noteText[k];
              if (char.charCodeAt(0) > 127 && char !== 'ñ') {
                noteText = noteText.replace(char, '');
              }
            }

            doc.setFontSize(9);
            doc.setTextColor(this.SECONDARY_COLOR);
            doc.text(noteText.trim(), 30, _y + 7, { align: 'left' });
            doc.setFontSize(11);
            doc.setTextColor(this.PRIMARY_COLOR);
            _y += 10;
          }
        }

        if (
          i % elPerPage === elPerPage - 1 &&
          i < this.data.countries.length - 1
        ) {
          doc.addPage();
          _y = 0;
        }
      }
    }

    private setTextDefinition(
      kind: 'text' | 'title' | 'subtitle' | 'key',
      doc: jsPDF
    ): void {
      switch (kind) {
        case 'title': {
          doc.setTextColor(this.PRIMARY_COLOR);
          doc.setFontSize(40);
          return;
        }
        case 'subtitle': {
          doc.setTextColor(this.PRIMARY_COLOR);
          doc.setFontSize(16);
          return;
        }
        case 'text': {
          doc.setTextColor(this.SECONDARY_COLOR);
          doc.setFontSize(11);
          return;
        }
        case 'key': {
          doc.setTextColor(this.PRIMARY_COLOR);
          doc.setFontSize(11);
          return;
        }
      }
    }

    private getElementsPerPageByCategory(category: CHSCategory): number {
      switch (category) {
        case CHSCategory.HealthCare:
          return 3;
        case CHSCategory.HealthSystem:
          return 4;
        case CHSCategory.PatientCoPayment:
          return 3;
        case CHSCategory.RemunerationOfPhysicians:
          return 4;
      }
    }
  };
}
