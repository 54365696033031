import { AnalysisBranch } from 'domain/models';

/*
  return false when both objects are equals
*/
export default function compareBranches(
  master: AnalysisBranch,
  draft: AnalysisBranch
): boolean {
  if (master !== draft) {
    return JSON.stringify(master) !== JSON.stringify(draft);
  }
  return false;
}
