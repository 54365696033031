enum ApplicationError {
  NoIndicatorSelected = 'app.root.no_indicator_selected',
  NoDataRetrieved = 'app.root.no_data_retrieved',
}

enum TableError {
  MissingParameters = 'app.table.missing_parameters',
}

enum BarChartError {
  MissingParameters = 'app.bar_chart.missing_parameters',
  DomainNotSpecified = 'app.bar_chart.domain_not_specified',
}

enum TimeSerieError {
  MissingParameters = 'app.time_serie.missing_parameters',
  DomainNotSpecified = 'app.time_serie.domain_not_specified',
}

enum ScatterChartError {
  MissingParameters = 'app.scatter_chart.missing_parameters',
  SingleIndicatorOrYear = 'app.scatter_chart.one_indicator_or_year',
}

enum MapError {
  MissingParameters = 'app.map.missing_parameters',
}

export type AppError =
  | ApplicationError
  | TableError
  | BarChartError
  | TimeSerieError
  | ScatterChartError
  | MapError;

export const AppErrors = {
  App: ApplicationError,
  Table: TableError,
  BarChart: BarChartError,
  TimeSerie: TimeSerieError,
  ScatterChart: ScatterChartError,
  Map: MapError,
} as const;
