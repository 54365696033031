import { Data, IndicatorSource } from './data.models';
import { Nullable } from './domain.models';
import { DefaultSettingsSelectsOption } from './store.models';

export type IndicatorRowData = {
  value: string;
  type: 'TEXT' | 'NUMBER' | 'MERGED';
  rowspan: number;
  rowKey?: boolean;
  data?: IndicatorDataEntry; // available if type = NUMBER
};

export type IndicatorNotesEntry = {
  indicator: string | null;
  label?: string;
  value?: string | IndicatorNotesEntry[];
};

export type IndicatorNotes = {
  entries: IndicatorNotesEntry[];
};

export type IndicatorTableData = {
  rowData: IndicatorRowData[][];
  headers: IndicatorHeaderEntry[];
  comments: IndicatorNotes;
  units: IndicatorUnit;
  sources: Sources;
};

export type IndicatorData = {
  data: IndicatorDataEntry[];
  comments: IndicatorNotes;
  units: IndicatorUnit;
  sources: Sources;
};

export type DiseaseReportData = {
  data: {
    [country: string]: {
      past: IndicatorDataEntry;
      now: IndicatorDataEntry;
    };
  }
  maxValue: number;
  minValue: number;
}

export type IndicatorUnit = {
  [indicator: string]: Unit;
};

export type Unit = {
  code: string;
  active: boolean;
};

export type Sources = {
  [indicator: string]: IndicatorSource;
};

export type IndicatorDataEntry = {
  id: number;
  indicator: string;
  sex: Nullable<string>;
  country: Nullable<string>;
  year: Nullable<string>;
  value: number;
  reference?: boolean; // Only present if true.
  data: Data;
  comments: string[];
};

export type IndicatorHeaderEntry = {
  code: string;
  value: string; // i18n code
  customCode?: string;
  variableOrIndicator: DefaultSettingsSelectsOption;
  colspan: number;
  rowspan: number;
  level: number;
  topHeaders?: IndicatorHeaderEntry[];
};

export enum References {
  EU = 'EU',
  EU_22 = 'EU-22',
  EU_24 = 'EU-24',
  EU_26 = 'EU-26',
  EU_28 = 'EU-28',
  EU_27_2007 = 'EU-27_2007',
  EU_28_2020 = 'EU-27_2020',
  EU_MEMBERS = 'EU_MEMBERS',
}

export const REFERENCES: References[] = [
  References.EU,
  References.EU_22,
  References.EU_24,
  References.EU_26,
  References.EU_27_2007,
  References.EU_28,
  References.EU_28_2020,
  References.EU_MEMBERS,
];

export interface Report {
  entries: ReportEntry[];
};

export interface ReportEntry {
  code: string;                   // Indicator code
  units: string | null;           // Units of the indicator
  countryValues: CountryValues;
  year: string;                   // year of the data (countryValues). Is a string because it can be 'ULTIMO', etc.
  comparisonYear: string;         // year of the comparison data (comparisonCountryValues)
  comparisonCountryValues: CountryValues;
};

export interface CountryValues {
  [country: string]: number;
};

export interface AllCountryValues {
  [country: string]: number[];
};

export interface MaxMinOpts {
  max?: number;
  min?: number;
};
