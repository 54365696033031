import { cancellablePromise, delay } from 'domain/utils';
import { useCancellablePromises } from './useCancelablePromise';

export const useClickPreventionOnDoubleClick = (
  onClick: (...args: any[]) => void,
  onDoubleClick: (...args: any[]) => void
) => {
  const api = useCancellablePromises();

  const handleClick = (...args: any[]) => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick(...args);
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = (...args: any[]) => {
    api.clearPendingPromises();
    onDoubleClick(...args);
  };

  return [handleClick, handleDoubleClick];
};
