import { ReactComponent as EuroMap } from 'assets/map_euro.svg';
import { renderToStaticMarkup } from 'react-dom/server';

import * as d3 from 'd3';

import {
  ColorPaletteGenerator,
  CustomPalette,
  CustomPaletteEntry,
  Palette,
} from 'domain/core/colors';
import {
  AnalysisBranch,
  IndicatorDataEntry,
  IndicatorNotes,
  MapSettingsSelectsOption,
} from 'domain/models';
import { TFunction } from 'i18next';
import {
  ControllerUtils,
  MultiFilterOrIndicator,
  PropCode,
} from './controller.utils';
import { extent, scaleQuantile } from 'd3';
import html2canvas, { Options } from 'html2canvas';
import jsPDF, { HTMLOptions } from 'jspdf';
import { getNumberFormatter } from '../numberformat.utils';
import { addCommentsToDoc } from './table.controller';
import { ColorScaleLimits, getColorScale, getCustomColorScale } from '../map.utils';

type SVG = d3.Selection<any, any, any, any>;
type MapDrawOptions = {
  dissagregationVar?: MapSettingsSelectsOption;
  dissagregationVarValue?: string;
  globalScale?: ColorScaleLimits;
};

export const SVG_EUROMAP_MARKUP = renderToStaticMarkup(<EuroMap />);

export class MapController {
  private constructor(
    private container: SVG,
    private svgContainer: SVG,
    private map: SVG,
    private t: TFunction,
    private language: string
  ) {}

  static getDataForSelectedGraphic(
    data: IndicatorDataEntry[],
    selectedGraphic: MultiFilterOrIndicator,
    dissagregationVar?: MapSettingsSelectsOption,
    dissagregationVarValue?: string
  ): IndicatorDataEntry[] {
    let result: IndicatorDataEntry[] = [];

    let dissVarDataProp = dissagregationVar
      ? ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
          dissagregationVar
        )
      : undefined;

    for (let entry of data) {
      let pass = true;
      for (let option of selectedGraphic.options) {
        const dataProp = entry[option.option] as PropCode;
        if (!dataProp || dataProp !== option.value) {
          pass = false;
        }
      }
      if (pass) {
        if (dissVarDataProp && dissagregationVarValue) {
          const dissDataProp = entry[dissVarDataProp] as PropCode;
          if (dissDataProp && dissDataProp === dissagregationVarValue) {
            result.push(entry);
          }
        } else {
          result.push(entry);
        }
      }
    }

    return result;
  }

  private drawMap(
    mapData: IndicatorDataEntry[],
    colorScale: ColorScaleLimits,
    noDataColor: string
  ): void {
    this.map.selectAll('.country').attr('fill', noDataColor);

    for (const entry of mapData) {
      const country = entry.country;
      const svgCountryName = ControllerUtils.getCountryMapping(country ?? '');

      if (!country || !entry.value || isNaN(entry.value) || !svgCountryName) {
        continue;
      }

      const color = d3.color(colorScale.scale(entry.value) as any)!.formatHex();

      this.map
        .selectAll(`.country.${svgCountryName}`)
        .attr('fill', color)
        .on('mousemove', (evt) => {
          if (entry && !isNaN(entry.value)) {
            const { x, y } = evt;
            this.updateTooltip(x, y, entry);
          } else {
            this.hideTooltip();
          }
        });
    }
  }

  private drawLegend(colorScale: ColorScaleLimits) {
    const colors = colorScale.scale.range();

    const numberFormatter = getNumberFormatter(this.language, {
      fixed: 2,
    });

    const palette = colors.map((c, i) => {
      let [_min, _max] = colorScale.limits.slice(i, i + 2);
      // Check if _max is undefined
      if (!_max) {
        _max = colorScale.limits[colorScale.limits.length - 1];
      }
      return {
        color: c,
        fromValue: _min,
        toValue: _max,
      };
    });

    const { clientWidth, clientHeight } = this.svgContainer.node()!;

    const stepHeight =
      (clientHeight -
        50 /* margin */ -
        50 /* bottom spacing */ -
        50) /* top spacing */ /
      palette.length;

    const legendContainer = this.svgContainer
      .append('g')
      .classed('legend-container', true);

    legendContainer
      .selectAll('g.legend-entry')
      .data([...palette.reverse()])
      .enter()
      .append('g')
      .classed('legend-entry', true)
      .attr('width', '50px')
      .attr('height', `${stepHeight}px`)
      .attr(
        'transform',
        (_, i) => `translate(${clientWidth - 45}, ${stepHeight * i + 125})`
      )
      .each((entry, index, elements) => {
        const last = index === elements.length - 1;
        const child = d3.select(elements[index]);
        child
          .append('rect')
          .attr('width', '20px')
          .attr('height', `${stepHeight}px`)
          .style('transform', `translateY(${stepHeight * index})`)
          .attr('fill', entry.color);

        const legendEntryText = child
          .append('text')
          .text(numberFormatter(entry.toValue ?? 0))
          .style('font-size', '10px')
          .style('fill', '#777')
          .attr('transform', `translate(-30, ${5})`);

        const textWidth = legendEntryText.node()?.getBoundingClientRect();

        legendEntryText.attr(
          'transform',
          `translate(${-(textWidth?.width ?? 0) - 5}, 5)`
        );

        if (last) {
          const lastTextEntry = child
            .append('text')
            .text(numberFormatter(entry.fromValue ?? 0))
            .style('font-size', '10px')
            .style('fill', '#777')
            .attr('transform', `translate(-30, ${stepHeight})`);

          const lastTextWidth = lastTextEntry.node()?.getBoundingClientRect();

          lastTextEntry.attr(
            'transform',
            `translate(${-(lastTextWidth?.width ?? 0) - 5}, ${stepHeight})`
          );
        }
      });
  }

  private createTooltip() {
    this.container
      .append('div')
      .classed('map-tooltip', true)
      .style('opacity', 0)
      .append('span')
      .classed('tooltip-text', true);

    this.map.on('mouseenter', this.showTooltip.bind(this));
    this.map.on('mouseleave', this.hideTooltip.bind(this));
  }

  private updateTooltip(x: number, y: number, entry: IndicatorDataEntry) {
    const numberFormatter = getNumberFormatter(this.language, {
      fixed: 2,
    });

    this.container
      .select('div.map-tooltip')
      .style('top', `${y + 15}px`)
      .style('left', `${x + 15}px`);

    this.container
      .select('span.tooltip-text')
      .text(
        this.t(`variables:country.${entry.country?.toLowerCase()}`) +
          ':' +
          numberFormatter(entry.value)
      );
  }

  private showTooltip() {
    this.container.select('div.map-tooltip').style('opacity', 1);
  }

  private hideTooltip() {
    this.container
      .select('div.map-tooltip')
      .style('opacity', 0)
      .style('top', '-10000px')
      .style('left', '-10000px');
  }

  private drawTitle(
    dissVar?: MapSettingsSelectsOption,
    dissVarValue?: string
  ): boolean {
    if (dissVar && dissVarValue) {
      const dataProp =
        ControllerUtils.getDefaultSettingsSelectsOptionDataProp(dissVar);
      const tPrefix =
        dissVar === 'INDICATOR'
          ? 'indicators:indicators'
          : `variables:${dataProp}`.toLowerCase();
      const text = this.t(`${tPrefix}.${dissVarValue}`.toLowerCase(), {
        defaultValue: dissVarValue,
      });

      this.svgContainer
        .append('text')
        .text(text)
        .attr('transform', 'translate(20, 30)')
        .attr('font-size', '14px')
        .attr('fill', '#666');

      return true;
    }
    return false;
  }

  private drawNoDataMarker(
    noDataColor: string,
    t: TFunction,
    hasTitle: boolean,
    refs: number
  ): void {
    const y =
      hasTitle && refs
        ? 60 + refs * 10
        : hasTitle && refs === 0
        ? 40
        : refs !== 0
        ? refs * 20 + 20
        : 20;

    const noDataContainer = this.svgContainer
      .append('g')
      .classed('no-data-color-container', true);

    noDataContainer
      .append('rect')
      .attr('x', 20)
      .attr('y', y)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', '20')
      .attr('height', '15')
      .attr('fill', noDataColor);

    noDataContainer
      .append('text')
      .text(t('analysis:map.options.noData.label') as string)
      .attr('font-size', '11px')
      .attr('fill', '#666')
      .attr('transform', `translate(45, ${y + 10})`);
  }

  private drawReferenceMarker(
    mapData: IndicatorDataEntry[],
    hasTitle: boolean,
    t: TFunction,
    language: string,
    colorScale: ColorScaleLimits
  ): number {
    const refData = mapData.filter((d) => d.reference === true);

    const numberFormatter = getNumberFormatter(language, {
      fixed: 2,
    });

    if (refData.length > 0) {
      const refContainer = this.svgContainer
        .append('g')
        .classed('ref-data-color-container', true);

      for (let i = 0; i < refData.length; i++) {
        const entry = refData[i];

        const y = (hasTitle ? 40 : 20) + 20 * i;

        const wrapper = refContainer.append('g');

        const _color = d3.color(colorScale.scale(entry.value) as any)!.formatHex();

        wrapper
          .append('rect')
          .attr('x', 20)
          .attr('y', y)
          .attr('rx', 3)
          .attr('ry', 3)
          .attr('width', '20')
          .attr('height', '15')
          .attr('fill', _color);

        wrapper
          .append('text')
          .text(
            `${t(`variables:country.${entry.country}`.toLowerCase(), {
              defaultValue: entry.country,
            })}: ${numberFormatter(entry.value)}`
          )
          .attr('font-size', '11px')
          .attr('fill', '#666')
          .attr('transform', `translate(45, ${y + 10})`);
      }
    }

    return refData.length;
  }

  static async createInstance(
    containerId: string,
    t: TFunction,
    language: string
  ): Promise<MapController> {
    try {
      const svg = d3.create('div').html(SVG_EUROMAP_MARKUP) as any;

      const container = d3.select(containerId);
      const svgContainer = d3
        .select(containerId)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%');

      const { clientWidth, clientHeight } = svgContainer.node()!;

      let mapG = svgContainer
        .append('g')
        .classed('svg-map-wrapper', true)
        .attr('transform', `translate(${15}, ${clientHeight * 0.2})`)
        .html(svg.node().firstChild.outerHTML);

      const map = mapG
        .select('svg')
        .attr('width', `${clientWidth * 0.8}px`)
        .attr('height', `${clientHeight * 0.8}px`);

      map.selectAll('.country').attr('fill', '#AEAEAE');
      map.selectAll('.border').style('stroke', '#FFF');

      return new MapController(container, svgContainer, map, t, language);
    } catch (err) {
      throw err;
    }
  }

  static computeQuintileScale(
    data: IndicatorDataEntry[],
    palette: CustomPalette | Palette
  ) {
    if (!ColorPaletteGenerator.isCustomPalete(palette[0])) {
      const sorted = data.sort((d1, d2) => d1.value - d2.value);

      return scaleQuantile<string, number>()
        .domain(sorted.map((s) => s.value))
        .range(palette as any[]);
    }
  }

  static getColorScale(
    data: IndicatorDataEntry[],
    master: AnalysisBranch
  ): ColorScaleLimits {
    const configPalette = master.mapSettings.palette.colorPalette;

    if (!ColorPaletteGenerator.isCustomPalete(configPalette[0])) {
      return getColorScale(data.map((d) => d.value));
    }

    const [min, max] = d3.extent(data, (d) => d.value) as [number, number];

    const palette = MapController.convertPaletteToCustomPalette(configPalette, [
      min,
      max,
    ]);

    const domain = palette.map((e) => e.fromValue);
    const colors = palette.map((e) => e.color);

    // Add the last value to the domain
    domain.push(palette[palette.length - 1].toValue);

    return getCustomColorScale(domain, colors, ColorPaletteGenerator.isCustomPalete(configPalette[0]));
  }

  static convertPaletteToCustomPalette(
    palette: Palette | CustomPalette,
    domain: [number, number]
  ): CustomPalette {
    const isColor = typeof palette[0] === 'string';

    if (!isColor) {
      return palette.slice() as CustomPalette;
    }

    const length = palette.length;

    const [min, max] = domain;
    const delta = (max - min) / length;

    const res: CustomPalette = [];

    for (let i = 0; i < length; i++) {
      const entry = palette[i];
      res.push({
        color: entry as string,
        fromValue: Number((min + delta * i).toFixed(2)),
        toValue: Number((min + delta * (i + 1)).toFixed(2)),
      });
    }

    return res;
  }

  static convertCustomPaletteToPalette(
    palette: CustomPalette | Palette
  ): Palette {
    const isColor = typeof palette[0] === 'string';

    return palette.map((p) => {
      if (isColor) {
        return p as string;
      } else {
        return (p as CustomPaletteEntry).color;
      }
    });
  }

  static async exportMapsToPng(
    element: HTMLElement,
    title: string,
    options?: Options
  ): Promise<boolean> {
    const restore = MapController.prepareExportMap();
    try {
      const canvas = await html2canvas(element, {
        ...options,
        scale: 4,
      });

      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');

      a.href = url;
      a.download = `${title}.png`;
      a.click();
      a.remove();
      return true;
    } catch (err) {
    } finally {
      restore();
      return false;
    }
  }

  static prepareExportMap() {
    const title = document.querySelectorAll('.selected-graphic.interactive');
    let lastTitleBgColor = 'transparent';
    if (title.length > 0) {
      // @ts-ignore
      lastTitleBgColor = title[0].style.backgroundColor;
      // @ts-ignore
      title[0].style.backgroundColor = 'transparent';
    }

    const maps = [...document.querySelectorAll('.svg-map')];
    if (maps.length == 0) {
      return () => {};
    }
    // @ts-ignore
    const lastBgColor = maps[0].style.backgroundColor;
    maps.map((m) => {
      // @ts-ignore
      m.style.backgroundColor = 'transparent';
    });
    return () => {
      if (title.length > 0) {
        // @ts-ignore
        title[0].style.backgroundColor = lastTitleBgColor;
      }
      maps.map((m) => {
        // @ts-ignore
        m.style.backgroundColor = lastBgColor;
      });
    };
  }

  static async exportMapToPdf(
    title: string,
    dissagregateVars: string[],
    comments: IndicatorNotes,
    options?: HTMLOptions
  ): Promise<boolean> {
    try {
      const doc = new jsPDF('p', 'px', 'a4', true);
      const docWidth = doc.internal.pageSize.width;

      doc.setFontSize(18);
      doc.text(title, docWidth / 2, 30, { align: 'center' });

      const margins = 10;
      const finalWidth = (docWidth - margins * 3) / 2;

      let lastY = 40;
      let startX = 0;

      if (dissagregateVars?.length <= 1) {
        const mapId = 'map';
        const map = document.getElementById(mapId) as HTMLDivElement;
        if (map) {
          const mapCanvas = await html2canvas(map);
          const x = docWidth / 2 - finalWidth / 2;
          doc.addImage(mapCanvas, 'JPEG', x, 60, finalWidth, finalWidth);
          lastY = 60 + finalWidth;
        }
      } else {
        const elPerRow = 2;
        for (let i = 0; i < dissagregateVars.length; i++) {
          let _i = i % 4; // 4 = elements per page
          const row = Math.floor(_i / elPerRow);
          const pos = i % elPerRow;

          if (i !== 0 && _i === 0) {
            doc.addPage();
          }

          const mapId = `map__${dissagregateVars[i]}`;
          const map = document.getElementById(mapId) as HTMLDivElement;
          if (map) {
            const mapCanvas = await html2canvas(map);

            doc.addImage(
              mapCanvas,
              'JPEG',
              (finalWidth + margins) * pos + margins,
              60 + (finalWidth + 30) * row,
              finalWidth,
              finalWidth
            );

            lastY = 60 + row * finalWidth;
            startX = startX == 0 ? finalWidth : 0;

            mapCanvas.remove();
          }
        }
      }

      addCommentsToDoc(doc, comments, lastY, startX);

      doc.save(title);
      doc.close();
      // eslint-disable-next-line
      (doc as any) = undefined;
      return true;
    } catch (err) {
      return false;
    }
  }

  public setT(t: TFunction) {
    this.t = t;
  }

  public setLanguage(language: string) {
    this.language = language;
  }

  public draw(
    data: IndicatorDataEntry[],
    selectedGraphic: MultiFilterOrIndicator,
    master: AnalysisBranch,
    options: MapDrawOptions
  ) {
    const mapData = MapController.getDataForSelectedGraphic(
      data,
      selectedGraphic,
      options.dissagregationVar,
      options.dissagregationVarValue
    );

    const colorScale =
      options.globalScale ?? MapController.getColorScale(mapData, master);

    this.drawMap(mapData, colorScale, master.mapSettings.noDataColor);
    this.drawLegend(colorScale);
    this.createTooltip();
    const hasTitle = this.drawTitle(
      options.dissagregationVar,
      options.dissagregationVarValue
    );
    const refs = this.drawReferenceMarker(
      mapData,
      hasTitle,
      this.t,
      this.language,
      colorScale
    );
    this.drawNoDataMarker(
      master.mapSettings.noDataColor,
      this.t,
      hasTitle,
      refs
    );
  }

  public dispose() {
    this.svgContainer.remove();
  }
}
