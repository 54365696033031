export const Api = {
  baseUrl:
    process.env.REACT_APP_BASE_URL ||
    (process.env.NODE_ENV === 'development'
      ? 'https://sanidad-ue.predictia.es'
      : window.location.origin),
  contact: '/public/contact',
  auth: {
    login: '/auth/signin',
    renew: '/auth/renew',
    register: '/register',
    account: '/users/me',
  },
  analysis: {
    data: '/data',
    notes: '/data/notes',
    table: '/data/table',
    tableXLS: '/data/table/xls', // Export to XLS
  },
  user: {
    me: '/me',
  },
  admin: {
    users: '/admin/users',
    roles: '/admin/users/roles',
    sources: '/admin/data/indicators/sources',
    dataSources: '/admin/data/sources',
    dumps: '/admin/data/dumps',
    import: '/admin/data/import',
    variables: {
      base: '/admin/variables',
      states: '/admin/variables/states',
    },
    indicators: {
      base: '/admin/indicators',
      sources: '/admin/indicators/sources',
      externalDataSources: '/admin/indicators/externalDataSources',
    },
    domains: '/admin/domains',
    translations: '/admin/translations',
    observations: '/admin/translations/observations',
    units: '/admin/indicators/units',
    exportComments: '/admin/translations/export-comments',
  },
  data: {
    signUrl: '/data/export/sign',
    indicators: {
      base: '/data/indicators',
      sources: '/data/indicators/sources',
      externalSources: '/data/indicators/sources/external',
    },
    reports: {
      base: '/public/reports',
      disease: '/public/reports/diseases',
    },
    languages: '/data/translations/languages',
    variables: '/data/indicators/variables',
    domains: '/data/indicators/domains',
    eurostat: {
      indicators: '/data/eurostat',
      params: '/data/eurostat/params',
      export: '/data/eurostat/export',
    },
    oecd: {
      indicators: '/data/oecd',
      params: '/data/oecd/params',
      export: '/data/oecd/export',
    },
    who: {
      indicators: '/data/who',
      params: '/data/who/params',
      export: '/data/who/export',
    },
    static: {
      xlsxCategory: '/data/static/systems/xlsx',
      xlsxCountry: '/data/static/systems/country/xlsx',
    },
  },
};

// Constants:
export const PDF_CONFIG = {
  FONT_SIZE: 12, // Font size for all text.
  TITLE_FONT_SIZE: 24, // Font size for the title.
  TABLE_START_Y: 30, // Margin between title and table.
  MAX_TITLE_LENGTH: 200, // Max width of title.
  MAX_COMMENTS_LENGTH: 380,
  MARGIN_X: 40,
  MARGIN_Y: 40,
  CELL_PADDING: 8,
  FIRST_COLUMN_WIDTH: 150,
  TAB: 10,
};
