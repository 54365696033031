export const COUNTRY_MAPPING: { [key: string]: string } = {
  DE: 'alemania',
  BE: 'belgica',
  FI: 'finlandia',
  PT: 'portugal',
  BG: 'bulgaria',
  DK: 'dinamarca',
  LT: 'lituania',
  LU: 'luxemburgo',
  HR: 'croacia',
  LV: 'letonia',
  FR: 'francia',
  HU: 'hungria',
  SE: 'suecia',
  SI: 'eslovenia',
  SK: 'eslovaquia',
  GB: 'reino_unido',
  IE: 'irlanda',
  EE: 'estonia',
  MT: 'malta',
  GR: 'grecia',
  IT: 'italia',
  ES: 'espana',
  AT: 'austria',
  CY: 'chipre',
  CZ: 'rep__checa',
  PL: 'polonia',
  RO: 'rumania',
  NL: 'paises_bajos',
};

export const COUNTRY_MAPPING_REVERSE: { [key: string]: string } =
  Object.fromEntries(Object.entries(COUNTRY_MAPPING).map((e) => [e[1], e[0]]));
