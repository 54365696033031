import { Token } from 'domain/models';

export const isValid = (token: Token): [boolean, number] => {
  const date = new Date(token.validity);
  const msDate = date.valueOf();

  const msNow = Date.now();

  const delta = msDate - msNow;
  return [delta > 0, delta];
};
