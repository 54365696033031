import { Indicator } from "domain/models";
import { useTranslation } from "react-i18next";

export const useIndicatorTitle = ({ code, unit, selectedYear }: { code?: string, unit?: string, selectedYear?: string }) => {
    const { t } = useTranslation('indicator');
    return indicatorTitleFormatter({
        t,
        code,
        unit,
        selectedYear,
    })
}

export const indicatorTitleFormatter = ({ t, code, unit, selectedYear }: { t: any, code?: string, unit?: string, selectedYear?: string }) => {
    const indicatorTitle = t('indicators:indicators.' + code);
    return `${indicatorTitle}${selectedYear ? ', ' + selectedYear  : ''}`;
}
