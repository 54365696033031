import { useState, useEffect, useCallback } from 'react';

export const getIsMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  const handler = useCallback(() => {
    setIsMobile(getIsMobile);
  }, [setIsMobile]);

  useEffect(() => {
    window.addEventListener('resize', handler, { passive: true });
    return () => window.removeEventListener('resize', handler);
  }, [handler]);

  return isMobile;
}
