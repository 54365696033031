import { Api } from 'domain/core';
import {
  DatasetList,
  EurostatParameters,
  IndicatorDataSource,
  KeyValue,
} from 'domain/models/data.models';
import { arrayArgsToQuery } from 'domain/utils';
import { BaseExportationService } from './base-exportation.service';
import { SignUrlService } from './signurl.service';

export class EurostatService extends BaseExportationService {
  public listDatasets(path: string[]): Promise<DatasetList> {
    return this.fetch(
      `${Api.baseUrl}${Api.data.eurostat.indicators}?path=${path.join(',')}`
    ).then((r) => r.json());
  }

  public getParams(param: string): Promise<EurostatParameters> {
    return this.fetch(`${Api.baseUrl}${Api.data.eurostat.params}/${param}`)
      .then((r) => r.json())
      .then((p) => p.parameters);
  }

  public getDownloadFromDataset(
    dataset: string,
    params: KeyValue<string | string[]>
  ) {
    const url = `${Api.baseUrl}${
      Api.data.eurostat.export
    }/${dataset}?${arrayArgsToQuery(params)}`;
    return new SignUrlService().signUrl(url).then((token) => {
      return `${url}&token=${token}&indicator=${dataset}`;
    });
  }

  public getDownload(
    indicator: IndicatorDataSource,
    params: KeyValue<string | string[]>
  ) {
    const url = `${Api.baseUrl}${Api.data.eurostat.export}/${
      indicator.dataset
    }?${arrayArgsToQuery(params)}`;
    return new SignUrlService().signUrl(url).then((token) => {
      return `${url}&token=${token}&indicator=${indicator.indicator}&datasource=${indicator.id}`;
    });
  }
}
