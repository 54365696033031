import {
  Option,
  filterOptions,
  getRealSelectedIndicators,
  Viz,
  OptionsKeys,
} from 'domain/hooks';
import {
  AnalysisBranch,
  AnalysisStore,
  BarChartSettings,
  ErrorComponent,
  ErrorSeverity,
  MapSettings,
  ScatterChartSettings,
  StoreCheckError,
  TableSettings,
  TimeSerieChartSettings,
  Variable,
} from 'domain/models';
import { AppErrors } from 'domain/models/errors';

export const checkStore = (analysisStore: AnalysisStore): StoreCheckError[] => {
  return [
    ...checkAppStore(analysisStore),
    ...checkTable(analysisStore),
    ...checkBarChart(analysisStore),
    ...checkTimeSerie(analysisStore),
    ...checkScatter(analysisStore),
    ...checkMap(analysisStore),
  ];
};

export const cleanUpStates = (branch: AnalysisBranch) => {
  const [tableSettings, tableSettingsNU] = cleanUpTableState(branch);
  const [barChartSettings, barChartSettingsNU] = cleanUpBarChartState(branch);
  const [timeSerieSettings, timeSerieSettingsNU] =
    cleanUpTimeSerieState(branch);
  const [scatterChartSettings, scatterChartSettingsNU] =
    cleanUpScatterChartState(branch);
  const [mapSettings, mapSettingsNU] = cleanUpMapState(branch);

  let newDraft = branch;

  if (tableSettingsNU) {
    newDraft = {
      ...newDraft,
      tableSettings,
    };
  }

  if (barChartSettingsNU) {
    newDraft = {
      ...newDraft,
      chartsSettings: {
        ...newDraft.chartsSettings,
        barChart: barChartSettings,
      },
    };
  }

  if (timeSerieSettingsNU) {
    newDraft = {
      ...newDraft,
      chartsSettings: {
        ...newDraft.chartsSettings,
        timeSerie: timeSerieSettings,
      },
    };
  }

  if (scatterChartSettingsNU) {
    newDraft = {
      ...newDraft,
      chartsSettings: {
        ...newDraft.chartsSettings,
        scatterChart: scatterChartSettings,
      },
    };
  }

  if (mapSettingsNU) {
    newDraft = {
      ...newDraft,
      mapSettings,
    };
  }

  return {
    newDraft,
    needsUpdate:
      tableSettingsNU ||
      barChartSettingsNU ||
      timeSerieSettingsNU ||
      scatterChartSettingsNU ||
      mapSettingsNU,
  };
};

const checkAppStore = (analysisStore: AnalysisStore): StoreCheckError[] => {
  const errors: StoreCheckError[] = [];

  if (analysisStore.draft.selectedIndicators.length === 0) {
    errors.push({
      code: AppErrors.App.NoIndicatorSelected,
      component: ErrorComponent.App,
      allowDataLoad: false,
      severity: ErrorSeverity.High,
    });
  }

  return errors;
};

const checkTable = (analysisStore: AnalysisStore): StoreCheckError[] => {
  const errors: StoreCheckError[] = [];

  const { columns, rows, tables } = analysisStore.draft.tableSettings;

  const tableAvailableOptions = getAvailableOptions(
    Viz.Table,
    analysisStore.draft,
    '*'
  );

  if (
    columns.length + rows.length + tables.length <
    tableAvailableOptions.length
  ) {
    errors.push({
      code: AppErrors.Table.MissingParameters,
      component: ErrorComponent.Table,
      allowDataLoad: false,
      severity: ErrorSeverity.Medium,
    });
  }

  return errors;
};

const checkBarChart = (analysisStore: AnalysisStore): StoreCheckError[] => {
  const errors: StoreCheckError[] = [];

  const { chartBy, disaggregateVariable1, disaggregateVariable2, domain } =
    analysisStore.draft.chartsSettings.barChart;

  const barChartAvailableOptions = getAvailableOptions(
    Viz.BarChart,
    analysisStore.draft,
    '*'
  );

  if (
    chartBy.length +
      disaggregateVariable1.length +
      disaggregateVariable2.length <
    barChartAvailableOptions.length
  ) {
    errors.push({
      code: AppErrors.BarChart.MissingParameters,
      component: ErrorComponent.BarChart,
      allowDataLoad: false,
      severity: ErrorSeverity.Medium,
    });
  }

  if (!domain[0] || !domain[1]) {
    errors.push({
      code: AppErrors.BarChart.DomainNotSpecified,
      component: ErrorComponent.BarChart,
      allowDataLoad: true,
      severity: ErrorSeverity.Medium,
    });
  }

  return errors;
};

const checkTimeSerie = (analysisStore: AnalysisStore): StoreCheckError[] => {
  const errors: StoreCheckError[] = [];

  const { chartBy, disaggregateVariable, domain } =
    analysisStore.draft.chartsSettings.timeSerie;

  const timeSerieAvailableOptions = getAvailableOptions(
    Viz.TimeSerie,
    analysisStore.draft,
    'INDICATOR',
    Variable.Country,
    Variable.Sex
  );

  if (
    chartBy.length + disaggregateVariable.length <
    timeSerieAvailableOptions.length
  ) {
    errors.push({
      code: AppErrors.TimeSerie.MissingParameters,
      component: ErrorComponent.TimeSerie,
      allowDataLoad: false,
      severity: ErrorSeverity.Medium,
    });
  }

  if (!domain[0] || !domain[1]) {
    errors.push({
      code: AppErrors.TimeSerie.DomainNotSpecified,
      component: ErrorComponent.TimeSerie,
      allowDataLoad: true,
      severity: ErrorSeverity.Medium,
    });
  }

  return errors;
};

const checkScatter = (analysisStore: AnalysisStore): StoreCheckError[] => {
  const errors: StoreCheckError[] = [];

  const { chartBy, xAxisIndicator, xAxisYear, yAxisIndicator, yAxisYear } =
    analysisStore.draft.chartsSettings.scatterChart;
  const {
    selectedFilters: { YEAR },
    selectedIndicators,
  } = analysisStore.draft;

  if (
    chartBy.length === 0 ||
    !xAxisIndicator ||
    !xAxisYear ||
    !yAxisIndicator ||
    !yAxisYear
  ) {
    errors.push({
      code: AppErrors.ScatterChart.MissingParameters,
      component: ErrorComponent.ScatterChart,
      allowDataLoad: true,
      severity: ErrorSeverity.Medium,
    });
  }

  if (selectedIndicators.length <= 1 && YEAR.length <= 1) {
    errors.push({
      code: AppErrors.ScatterChart.SingleIndicatorOrYear,
      component: ErrorComponent.ScatterChart,
      allowDataLoad: true,
      severity: ErrorSeverity.Medium,
    });
  }

  return errors;
};

const checkMap = (analysisStore: AnalysisStore): StoreCheckError[] => {
  const errors: StoreCheckError[] = [];

  const { chartsBy, disaggregateVariable } = analysisStore.draft.mapSettings;

  if (chartsBy.length + disaggregateVariable.length < 3) {
    errors.push({
      code: AppErrors.Map.MissingParameters,
      component: ErrorComponent.Map,
      allowDataLoad: true,
      severity: ErrorSeverity.Medium,
    });
  }

  return errors;
};

const getAvailableOptions = (
  viz: Viz,
  draft: AnalysisBranch,
  ...pick: OptionsKeys[]
): Option[] => {
  const indicators = getRealSelectedIndicators(viz, draft);
  const filtered = filterOptions(pick, indicators);
  return Object.values(filtered)
    .filter((fn) => !!fn)
    .map((fn) => fn!((k: string) => k));
};

const cleanUpTableState = (
  branch: AnalysisBranch
): [TableSettings, boolean] => {
  let draft = branch.tableSettings,
    needsUpdate = false;

  const options = getAvailableOptions(Viz.Table, branch, '*');

  const { columns, rows, tables } = branch.tableSettings;

  for (let col of columns) {
    const inOptions = options.find((o) => o.value === col);
    const inRows = rows.includes(col);
    const inTables = tables.includes(col);

    if (!inOptions || inRows || inTables) {
      draft = {
        ...draft,
        columns: columns.filter((c) => c !== col),
      };
      needsUpdate = true;
    }
  }

  for (let row of rows) {
    const inOptions = options.find((o) => o.value === row);
    const inTables = tables.includes(row);

    if (!inOptions || inTables) {
      draft = {
        ...draft,
        rows: rows.filter((r) => r !== row),
      };
      needsUpdate = true;
    }
  }

  for (let table of tables) {
    const inOptions = options.find((o) => o.value === table);

    if (!inOptions) {
      draft = {
        ...draft,
        tables: tables.filter((t) => t !== table),
      };
      needsUpdate = true;
    }
  }

  const fullSelectedOptions = columns.concat(rows).concat(tables);

  if (fullSelectedOptions.length < options.length) {
    needsUpdate = true;

    const missingOptions = options.filter(
      (o) => !fullSelectedOptions.includes(o.value)
    );

    for (let opt of missingOptions) {
      if (draft.tables.length < 1) {
        draft = {
          ...draft,
          tables: [opt.value],
        };
      } else if (draft.rows.length < 2) {
        draft = {
          ...draft,
          rows: draft.rows.concat(opt.value),
        };
      } else if (draft.columns.length < 2) {
        draft = {
          ...draft,
          columns: draft.columns.concat(opt.value),
        };
      }
    }
  }

  return [draft, needsUpdate];
};

const cleanUpTimeSerieState = (
  branch: AnalysisBranch
): [TimeSerieChartSettings, boolean] => {
  let draft = branch.chartsSettings.timeSerie,
    needsUpdate = false;

  const options = getAvailableOptions(
    Viz.TimeSerie,
    branch,
    'INDICATOR',
    Variable.Country,
    Variable.Sex
  );

  const { chartBy, disaggregateVariable } = branch.chartsSettings.timeSerie;

  for (let dissVar of disaggregateVariable) {
    const inOptions = options.find((o) => o.value === dissVar);
    const inChartBy = chartBy.includes(dissVar);

    if (!inOptions || inChartBy) {
      draft = {
        ...draft,
        disaggregateVariable: disaggregateVariable.filter((d) => d !== dissVar),
      };
      needsUpdate = true;
    }
  }

  for (let cb of chartBy) {
    const inOptions = options.find((o) => o.value === cb);

    if (!inOptions) {
      draft = {
        ...draft,
        chartBy: chartBy.filter((c) => c !== cb),
      };
      needsUpdate = true;
    }
  }

  const fullSelectedOptions = chartBy.concat(disaggregateVariable);

  if (fullSelectedOptions.length < options.length) {
    needsUpdate = true;

    const missingOptions = options.filter(
      (o) => !fullSelectedOptions.includes(o.value)
    );

    for (let opt of missingOptions) {
      if (draft.disaggregateVariable.length < 1) {
        draft = {
          ...draft,
          disaggregateVariable: [opt.value],
        };
      } else if (draft.chartBy.length < 2) {
        draft = {
          ...draft,
          chartBy: draft.chartBy.concat(opt.value),
        };
      }
    }
  }

  return [draft, needsUpdate];
};

const cleanUpBarChartState = (
  branch: AnalysisBranch
): [BarChartSettings, boolean] => {
  let draft = branch.chartsSettings.barChart,
    needsUpdate = false;

  const options = getAvailableOptions(Viz.BarChart, branch, '*');

  const { chartBy, disaggregateVariable1, disaggregateVariable2 } =
    branch.chartsSettings.barChart;

  for (let dissVar of disaggregateVariable1) {
    const inOptions = options.find((o) => o.value === dissVar);
    const inChartBy = chartBy.includes(dissVar);
    const inDissVar = disaggregateVariable2.includes(dissVar);

    if (!inOptions || inChartBy || inDissVar) {
      draft = {
        ...draft,
        disaggregateVariable1: disaggregateVariable1.filter(
          (d) => d !== dissVar
        ),
      };
      needsUpdate = true;
    }
  }

  for (let dissVar of disaggregateVariable2) {
    const inOptions = options.find((o) => o.value === dissVar);
    const inChartBy = chartBy.includes(dissVar);

    if (!inOptions || inChartBy) {
      draft = {
        ...draft,
        disaggregateVariable2: disaggregateVariable2.filter(
          (d) => d !== dissVar
        ),
      };
      needsUpdate = true;
    }
  }

  for (let cb of chartBy) {
    const inOptions = options.find((o) => o.value === cb);

    if (!inOptions) {
      draft = {
        ...draft,
        chartBy: chartBy.filter((c) => c !== cb),
      };
      needsUpdate = true;
    }
  }

  const fullSelectedOptions = disaggregateVariable1
    .concat(disaggregateVariable2)
    .concat(chartBy);

  if (fullSelectedOptions.length < options.length) {
    needsUpdate = true;

    const missingOptions = options.filter(
      (o) => !fullSelectedOptions.includes(o.value)
    );

    for (let opt of missingOptions) {
      if (draft.disaggregateVariable2.length < 1) {
        draft = {
          ...draft,
          disaggregateVariable2: [opt.value],
        };
      } else if (draft.disaggregateVariable1.length < 1) {
        draft = {
          ...draft,
          disaggregateVariable1: [opt.value],
        };
      } else if (draft.chartBy.length < 2) {
        draft = {
          ...draft,
          chartBy: draft.chartBy.concat(opt.value),
        };
      }
    }
  }

  return [draft, needsUpdate];
};

const cleanUpScatterChartState = (
  branch: AnalysisBranch
): [ScatterChartSettings, boolean] => {
  let draft = branch.chartsSettings.scatterChart,
    needsUpdate = false;

  const options = getAvailableOptions(
    Viz.ScatterChart,
    branch,
    Variable.Sex,
    Variable.Country
  );

  const { chartBy } = branch.chartsSettings.scatterChart;

  for (let cb of chartBy) {
    const inOptions = options.find((o) => o.value === cb);

    if (!inOptions) {
      const newOption = options.length !== 0 ? options[0] : undefined;

      draft = {
        ...draft,
        chartBy:
          newOption && newOption.value
            ? ([newOption.value] as any)
            : chartBy.filter((o) => o !== cb),
      };
      needsUpdate = true;
    }
  }

  if (chartBy.length !== 1 && options.length !== 0) {
    needsUpdate = true;

    draft = {
      ...draft,
      chartBy: [options[0].value as any],
    };
  }

  return [draft, needsUpdate];
};

export const cleanUpMapState = (
  branch: AnalysisBranch
): [MapSettings, boolean] => {
  let draft = branch.mapSettings,
    needsUpdate = false;

  const options = getAvailableOptions(
    Viz.Map,
    branch,
    Variable.Sex,
    Variable.Year,
    'INDICATOR'
  );

  const { chartsBy, disaggregateVariable } = draft;

  for (let dissVar of disaggregateVariable) {
    const inOptions = options.find((o) => o.value === dissVar);
    const inChartBy = chartsBy.includes(dissVar);

    if (!inOptions || inChartBy) {
      draft = {
        ...draft,
        disaggregateVariable: [],
      };

      needsUpdate = true;
    }
  }

  for (let cb of chartsBy) {
    const inOption = options.find((o) => o.value === cb);

    if (!inOption) {
      draft = {
        ...draft,
        chartsBy: draft.chartsBy.filter((c) => c !== cb),
      };

      needsUpdate = true;
    }
  }

  const fullSelectedOptions = chartsBy.concat(disaggregateVariable);

  if (fullSelectedOptions.length < options.length) {
    needsUpdate = true;

    const missingOptions = options.filter(
      (o) => !fullSelectedOptions.includes(o.value as any)
    );

    for (let opt of missingOptions) {
      if (draft.chartsBy.length < 3) {
        draft = {
          ...draft,
          chartsBy: draft.chartsBy.concat(opt.value as any),
        };
      } else if (draft.disaggregateVariable.length < 1) {
        draft = {
          ...draft,
          disaggregateVariable: [opt.value as any],
        };
      }
    }
  }

  return [draft, needsUpdate];
};
