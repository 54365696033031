import { extent, format, rollup, sum } from 'd3';
import { PDF_CONFIG } from 'domain/core/constants';
import {
  AnalysisBranch,
  AvailableCharts,
  BarChartSettings,
  BarChartSort,
  ChartAxis,
  ChartOrientation,
  ChartsSettings,
  DefaultSettingsSelectsOption,
  IndicatorDataEntry,
  IndicatorNotes,
  isBarChartSettings,
  isScatterChartSettings,
  MenuIndicator,
  Nullable,
  ScatterChartSettings,
  TimeSerieChartSettings,
} from 'domain/models';
import html2canvas, { Options } from 'html2canvas';
import { TFunction } from 'i18next';
import jsPDF, { HTMLOptions } from 'jspdf';
import { AxisDomain } from 'recharts/types/util/types';
import { delay } from '../common.utils';
import { calculateNearBreak } from '../number.utils';
import {
  ControllerUtils,
  FilterOrIndicatorKeys,
  MultiFilterOrIndicator,
  PropCode,
} from './controller.utils';
import { addCommentsToDoc } from './table.controller';

type RechartsDataEntry = {
  year: string;
  sex: Nullable<string>;
  indicator: string;
  country: string;
} & { [dissValues: string]: number };
export type RechartsData = RechartsDataEntry[];
export type LineConfig = {
  line: string;
  key: string;
  isRef?: boolean;
  isSpain: boolean;
  isRegLine: boolean;
};

export type BarConfig = {
  bar: string;
  key: string;
  isRef?: boolean;
  isSpain: boolean;
};

export type ScatterChartDataEntry = {
  x: number;
  y: number;
  dissVar: string;
  dissVarProp: 'sex' | 'country';
  country: Nullable<string>;
  sex: Nullable<string>;
  reference: boolean;
};
export type ScatterChartData = ScatterChartDataEntry[];

export type TableHeader = {
  variable: FilterOrIndicatorKeys;
  code: Nullable<string>;
  label: string;
};
export type TableDataRow = Array<string | number>;
export type TableConfig = {
  tableHeaders: TableHeader[];
  tableData: TableDataRow[];
};

type ChartAxisProps = 'sex' | 'year' | 'country' | 'value';

export class ChartController {
  static download_count = 0;

  static axisWidth(ticks: number[]) {
    return (
      35 +
      Math.max.apply(
        null,
        ticks.map((t) => t.toString().length)
      ) *
        6
    );
  }

  static ticks(axisDomain: AxisDomain, fromZero: boolean) {
    const first: number = fromZero ? 0 : (Number(axisDomain[0]) as number);
    const second: number = Number(axisDomain[1]) as number;
    const steps = ChartController.findBestSteps(first, second);
    const ticks = [];
    for (let i = 0; i <= steps; i++) {
      ticks.push(first + (i * (second - first)) / steps);
    }
    return ticks;
  }

  static findBestSteps(first: number, last: number) {
    const toZero = last - first;
    const n = toZero / Math.pow(10, Math.floor(Math.log10(toZero)));

    if (n == 1) {
      // 1
      return 4;
    }
    if (n % 2 == 0) {
      // 2, 4, 6, 8
      return 4;
    }
    if (n % 3 == 0) {
      // 3, (6), 9
      return 3;
    }
    // 7
    return 2;
  }

  //#region Bar chart

  static BarChart = class BartChartController {
    static formatData(
      data: IndicatorDataEntry[],
      chartSettings: BarChartSettings
    ): { chartData: RechartsData; bars: BarConfig[] } {
      let result: RechartsData = [];
      let bars: BarConfig[] = [];

      const props: (keyof IndicatorDataEntry)[] = [
        'country',
        'indicator',
        'sex',
        'year',
      ];

      let dissVar2, dissVar2Values;

      if (chartSettings.disaggregateVariable2[0]) {
        dissVar2 = ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
          chartSettings.disaggregateVariable2[0]
        );
        dissVar2Values = ControllerUtils.getDissVarValues(data, dissVar2);
      } else {
        bars.push({ key: 'value', bar: 'value', isSpain: false, isRef: false });
      }

      const dissVar1 = ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
        chartSettings.disaggregateVariable1[0]
      );
      let dissVar1Values = ControllerUtils.getDissVarValues(data, dissVar1);

      if (chartSettings.order === BarChartSort.DisagregateVariableValue) {
        dissVar1Values = dissVar1Values.sort((v1, v2) => v1.localeCompare(v2));
      }

      for (let uniqProp of dissVar1Values) {
        const entries = data.filter((d) => {
          const prop = d[dissVar1] as PropCode;
          if (prop === uniqProp) {
            return true;
          }
          return false;
        });

        let resEntry: any = {};

        for (let entry of entries) {
          for (let prop of props) {
            const propValue = entry[prop] as PropCode;
            if (propValue && propValue) {
              resEntry[prop] = propValue;
            }
          }

          if (entry.reference) {
            resEntry.isRef = true;
          }

          if (entry.country?.toUpperCase() === 'ES') {
            resEntry.isSpain = true;
          }

          if (dissVar2Values && dissVar2) {
            const prop = entry[dissVar2] as PropCode;
            if (prop) {
              const dissValueKey = `value_${prop}`;
              if (!bars.find((bc) => bc.key === dissValueKey)) {
                bars.push({
                  key: dissValueKey,
                  bar: dissValueKey,
                  isRef: entry.reference,
                  isSpain: entry.country?.toUpperCase() === 'ES',
                });
              }

              resEntry[dissValueKey] = entry.value;
            }
          } else {
            resEntry.value = entry.value;
          }
        }

        result.push(resEntry);
      }

      if (chartSettings.order !== BarChartSort.DisagregateVariableValue) {
        result = result.sort((e1, e2) => {
          let e1Values: number[] = [],
            e2Values: number[] = [];
          for (let bar of bars) {
            if (e1[bar.key] !== undefined) {
              e1Values.push(e1[bar.key]);
            }
            if (e2[bar.key] !== undefined) {
              e2Values.push(e2[bar.key]);
            }
          }

          if (chartSettings.order === BarChartSort.ValueAsc) {
            const e1Max = Math.max(...e1Values);
            const e2Max = Math.max(...e2Values);

            return e1Max - e2Max;
          } else {
            const e1Min = Math.max(...e1Values);
            const e2Min = Math.max(...e2Values);

            return e2Min - e1Min;
          }
        });
      }

      return { chartData: result, bars };
    }

    static getAxisDataKey(
      chartSettings: BarChartSettings,
      axis: ChartAxis
    ): ChartAxisProps {
      if (chartSettings.orientation === ChartOrientation.Vertical) {
        if (axis === ChartAxis.X) {
          return ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
            chartSettings.disaggregateVariable1[0]
          ) as ChartAxisProps;
        } else {
          return 'value';
        }
      } else {
        if (axis === ChartAxis.X) {
          return 'value';
        } else {
          return ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
            chartSettings.disaggregateVariable1[0]
          ) as ChartAxisProps;
        }
      }
    }

    static buildTable(
      data: IndicatorDataEntry[],
      barChartSettings: BarChartSettings,
      t: TFunction
    ): TableConfig {
      const { disaggregateVariable1, disaggregateVariable2 } = barChartSettings;

      const dissVar1Prop =
        ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
          disaggregateVariable1[0]
        );
      const dissVar2Prop =
        ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
          disaggregateVariable2[0]
        );

      const dissVar1Values = ControllerUtils.getDissVarValues(
        data,
        dissVar1Prop
      );
      const dissVar2Values = ControllerUtils.getDissVarValues(
        data,
        dissVar2Prop
      );

      let headers: TableHeader[] = [];

      const dissVar1Tr = t(
        `variables:${disaggregateVariable1[0]}.translation`.toLowerCase()
      );
      headers.push({
        code: null,
        label: dissVar1Tr,
        variable: dissVar1Prop,
      });

      const transValue = (
        value: string,
        variable: FilterOrIndicatorKeys
      ): string => {
        if (variable === 'indicator') {
          return `indicators:indicators.${value}`.toLowerCase();
        } else {
          return `variables:${variable}.${value}.translation`.toLowerCase();
        }
      };

      for (let diss2Val of dissVar2Values) {
        headers.push({
          code: diss2Val,
          variable: dissVar2Prop,
          label: t(transValue(diss2Val, dissVar2Prop), {
            defaultValue: diss2Val,
          }),
        });
      }

      const dataByDis1Var = rollup(
        data,
        (d) => d,
        (d) => d[dissVar1Prop]
      );

      const rows: TableDataRow[] = [];
      for (let diss1Val of dissVar1Values) {
        let row: TableDataRow = [];
        for (let header of headers) {
          if (header.code) {
            const entry = dataByDis1Var.get(diss1Val)?.find((d) => {
              const entryData = d[header.variable];
              if (entryData && entryData === header.code) {
                return true;
              }
              return false;
            });
            if (entry) {
              row.push(entry.value);
            }
          } else {
            row.push(diss1Val);
          }
        }

        rows.push(row);
      }

      return {
        tableData: rows,
        tableHeaders: headers,
      };
    }

    static getValueLabel(
      raw: string,
      settings: BarChartSettings,
      t: TFunction
    ): string {
      const dissVar = settings.disaggregateVariable2[0];
      return ChartController.getValueLabel(raw, dissVar, t, true);
    }

    static getTooltipValue(
      rawLabel: string,
      barChartSettings: BarChartSettings,
      t: TFunction
    ): string {
      const dissVar2 = barChartSettings.disaggregateVariable2[0];
      return ChartController.getValueLabel(rawLabel, dissVar2, t, true);
    }

    static getTooltipTitle(
      label: string,
      barChartSettings: BarChartSettings,
      t: TFunction
    ): string {
      const dissVar = barChartSettings.disaggregateVariable1[0];
      return ChartController.getValueLabel(label, dissVar, t);
    }
  };

  //#endregion

  //#region Time serie

  static TimeSerie = class TimeSerieController {
    static formatData(
      data: IndicatorDataEntry[],
      timeSerieSettings: TimeSerieChartSettings
    ): { chartData: RechartsData; lines: LineConfig[] } {
      const result: RechartsData = [];
      const lines: LineConfig[] = [];

      const props: (keyof IndicatorDataEntry)[] = [
        'country',
        'indicator',
        'sex',
        'year',
      ];

      const dissVar = ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
        timeSerieSettings.disaggregateVariable[0]
      );

      const years = ControllerUtils.sortYears(
        data
          .map((d) => d.year)
          .filter((y, i, arr) => !!y && arr.indexOf(y) === i) as string[]
      );

      for (let year of years) {
        const entries = data.filter((d) => d.year === year);

        let resEntry: any = {};

        for (let entry of entries) {
          for (let prop of props) {
            const propValue = entry[prop] as PropCode;
            if (propValue && propValue) {
              resEntry[prop] = propValue;
            }
          }

          const dissProp = entry[dissVar] as PropCode;
          if (dissProp) {
            let lineKey = `value_${dissProp}`;

            resEntry[lineKey] = entry.value;

            if (!lines.find((lc) => lc.key === lineKey)) {
              lines.push({
                line: dissProp,
                key: lineKey,
                isRef: entry.reference,
                isSpain: dissProp.toUpperCase() === 'ES',
                isRegLine: false,
              });
            }
          }
        }
        result.push(resEntry);
      }

      const baseData = timeSerieSettings.movingAverage
        ? ChartController.TimeSerie.applyMovingAverage(result, years, lines)
        : result;

      if (timeSerieSettings.regressionLine) {
        return ChartController.TimeSerie.calculateRegresionLines(
          baseData,
          lines
        );
      }

      return { chartData: baseData, lines };
    }

    static applyMovingAverage(
      data: RechartsData,
      years: string[],
      lines: LineConfig[]
    ): RechartsData {
      const K = 1;
      const length = years.length;

      let res: RechartsData = data;

      for (let line of lines) {
        for (let i = 0; i < length; i++) {
          const minIndex = Math.max(0, i - K);
          const maxIndex = Math.min(length - 1, i + K);
          const subset = data
            .slice(minIndex, maxIndex + 1)
            .filter((d) => !isNaN(d[line.key]));

          const mean = sum(subset, (e) => e[line.key]) / subset.length;

          const year = years[i];

          res = res.map((r) => {
            if (r.year === year) {
              return {
                ...r,
                [line.key]: mean,
              };
            }
            return r;
          });
        }
      }

      return res;
    }

    static calculateRegresionLines(
      data: RechartsData,
      lines: LineConfig[]
    ): {
      chartData: RechartsData;
      lines: LineConfig[];
    } {
      let res: RechartsData = data.slice();
      let newLines: LineConfig[] = lines;

      for (let line of lines) {
        let x = [],
          y = [];
        for (let entry of data) {
          x.push(Number(entry.year));
          y.push(entry[line.key]);
        }

        const leastSqrt = ChartController.computeLeastSquares(x, y);
        const lineK = `${line.key}__regLine`;
        const regLine: LineConfig = {
          ...line,
          key: lineK,
          isRegLine: true,
        };
        newLines = newLines.concat(regLine);

        res = res.map((entry) => {
          const lsqrtEntry = leastSqrt.find((d) => d.x === Number(entry.year));
          if (lsqrtEntry) {
            return {
              ...entry,
              [lineK]: lsqrtEntry.y,
            };
          }
          return entry;
        });
      }

      return {
        chartData: res,
        lines: newLines,
      };
    }

    static buildTable(
      data: IndicatorDataEntry[],
      timeSerieSettings: TimeSerieChartSettings,
      t: TFunction
    ): TableConfig {
      const { disaggregateVariable } = timeSerieSettings;

      const dissVar = ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
        disaggregateVariable[0]
      );

      const dissVarValues = ControllerUtils.getDissVarValues(data, dissVar);
      const years = ControllerUtils.getDissVarValues(data, 'year');

      let headers: TableHeader[] = [];

      const dissVar1Tr = t(`settings:variables.year`);
      headers.push({
        code: null,
        label: dissVar1Tr,
        variable: dissVar,
      });

      const transValue = (
        value: string,
        variable: FilterOrIndicatorKeys
      ): string => {
        if (variable === 'indicator') {
          return `indicators:indicators.${value}`.toLowerCase();
        } else {
          return `variables:${variable}.${value}`.toLowerCase();
        }
      };

      for (let dissVal of dissVarValues) {
        headers.push({
          code: dissVal,
          variable: dissVar,
          label: t(transValue(dissVal, dissVar)),
        });
      }

      const dataByYear = rollup(
        data,
        (d) => d,
        (d) => d.year
      );

      const rows: TableDataRow[] = [];
      for (let year of years) {
        let row: TableDataRow = [];
        for (let header of headers) {
          if (header.code) {
            const entry = dataByYear.get(year)?.find((d) => {
              const entryData = d[header.variable];
              if (entryData && entryData === header.code) {
                return true;
              }
              return false;
            });
            if (entry) {
              row.push(entry.value);
            }
          } else {
            row.push(year);
          }
        }

        rows.push(row);
      }

      return {
        tableData: rows,
        tableHeaders: headers,
      };
    }

    static getValueLabel(
      raw: string,
      timeSerieSettings: TimeSerieChartSettings,
      t: TFunction
    ): string {
      const dissVar = timeSerieSettings.disaggregateVariable[0];
      return ChartController.getValueLabel(raw, dissVar, t, true);
    }
  };

  //#endregion

  //#region Scatter chart

  static ScatterChart = class ScatterChartController {
    static formatData(
      data: IndicatorDataEntry[],
      scatterChartSettings: ScatterChartSettings
    ): ScatterChartData {
      const { xAxisIndicator, xAxisYear, yAxisIndicator, yAxisYear } =
        scatterChartSettings;

      const xAxisData = ChartController.ScatterChart.getIndicatorData(
        data,
        xAxisIndicator,
        xAxisYear
      );
      const yAxisData = ChartController.ScatterChart.getIndicatorData(
        data,
        yAxisIndicator,
        yAxisYear
      );

      return ChartController.ScatterChart.mergeData(
        xAxisData,
        yAxisData,
        scatterChartSettings
      );
    }

    static getIndicatorData(
      data: IndicatorDataEntry[],
      indicator: Nullable<MenuIndicator>,
      year: Nullable<string>
    ): IndicatorDataEntry[] {
      if (indicator && year) {
        const indicatorData = data.filter(
          (d) => d.indicator === indicator.code
        );
        const realYear = ControllerUtils.getYearRealValue(indicatorData, year);
        return indicatorData.filter((entry) => {
          if (entry.year && entry.year === realYear) {
            return true;
          }
          return false;
        });
      }
      return [];
    }

    static mergeData(
      xAxisData: IndicatorDataEntry[],
      yAxisData: IndicatorDataEntry[],
      scatterChartSettings: ScatterChartSettings
    ): ScatterChartData {
      let result: ScatterChartData = [],
        collA,
        collB,
        collAId: keyof Pick<ScatterChartDataEntry, 'x' | 'y'> = 'x',
        collBId: keyof Pick<ScatterChartDataEntry, 'x' | 'y'> = 'x';

      if (xAxisData.length >= yAxisData.length) {
        collA = xAxisData;
        collAId = 'x';
        collB = yAxisData;
        collBId = 'y';
      } else {
        collA = yAxisData;
        collAId = 'y';
        collB = xAxisData;
        collBId = 'x';
      }

      const dissVarProp =
        ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
          scatterChartSettings.chartBy[0]
        );

      for (let i = 0; i < collA.length; i++) {
        const firstEntry = collA[i];
        const dissVar = firstEntry[dissVarProp];
        if (!dissVar && dissVarProp !== 'sex') {
          continue;
        }
        const secondEntry = collB.find(
          (entry) =>
            (entry.country && firstEntry.country
              ? entry.country === firstEntry.country
              : true) &&
            (entry.sex && firstEntry.sex ? entry.sex === firstEntry.sex : true)
        );

        if (!secondEntry) {
          continue;
        }
        result.push({
          [collAId]: firstEntry.value,
          [collBId]: secondEntry.value,
          dissVar: dissVar,
          dissVarProp: dissVarProp as any,
          country: firstEntry.country,
          sex: firstEntry.sex,
          reference: Boolean(firstEntry.reference || secondEntry.reference),
        } as any);
      }

      return result;
    }

    static getBestFitLine(data: ScatterChartData, domain: AxisDomain): any {
      let x = [],
        y = [];

      for (let entry of data) {
        x.push(entry.x);
        y.push(entry.y);
      }

      const bfl = ChartController.computeLeastSquares(
        x,
        y,
        domain as [number, number]
      );

      return [bfl[0], bfl[bfl.length - 1]];
    }

    static getAvailableYears(
      years: string[],
      data: IndicatorDataEntry[],
      scatterChartSettings: ScatterChartSettings
    ): { xYears: string[]; yYears: string[] } {
      const { xAxisIndicator, yAxisIndicator } = scatterChartSettings;

      const xData = data.filter((d) => d.indicator === xAxisIndicator?.code);
      const yData = data.filter((d) => d.indicator === yAxisIndicator?.code);

      const getYears = (_data: IndicatorDataEntry[]) =>
        years.filter((year) => {
          const hasYear = _data.some((d) => {
            const yearValue = ControllerUtils.getYearRealValue(_data, year);
            const dataYearValue = ControllerUtils.getYearRealValue(
              _data,
              d.year?.toString() || ''
            );
            return yearValue === dataYearValue;
          });

          return hasYear;
        });

      const xYears = getYears(xData);
      const yYears = getYears(yData);

      return {
        xYears,
        yYears,
      };
    }

    static getAxisDomain(
      data: ScatterChartData,
      shareScales: boolean
    ): {
      x: AxisDomain;
      y: AxisDomain;
    } {
      const [xMin, xMax] = extent(data, (d) => d.x) as [number, number];
      const [yMin, yMax] = extent(data, (d) => d.y) as [number, number];

      const newXMax = calculateNearBreak(xMax, 1);
      const newXMin = calculateNearBreak(xMin, 0);

      const newYMax = calculateNearBreak(yMax, 1);
      const newYMin = calculateNearBreak(yMin, 0);

      return shareScales
        ? {
            x: [Math.min(newXMin, newYMin), Math.max(newYMax, newXMax)],
            y: [Math.min(newXMin, newYMin), Math.max(newYMax, newXMax)],
          }
        : {
            x: [newXMin, newXMax],
            y: [newYMin, newYMax],
          };
    }

    static getInitialSettings(
      selectedYears: string[],
      indicators: MenuIndicator[],
      scatterChartSettings: ScatterChartSettings
    ): ScatterChartSettings {
      const manyIndicators = indicators.length > 1;
      const hasIndicators = indicators.length !== 0;
      const manyYears = selectedYears.length > 1;
      const hasYears = selectedYears.length !== 0;

      const { xAxisIndicator, xAxisYear, yAxisIndicator, yAxisYear } =
        scatterChartSettings;

      if (xAxisIndicator && xAxisYear && yAxisIndicator && yAxisYear) {
        const xAxisIndFound = indicators.find(
          (i) => i.code === xAxisIndicator.code
        );
        const yAxisIndFound = indicators.find(
          (i) => i.code === yAxisIndicator.code
        );

        const xAxisYearFound = selectedYears.includes(xAxisYear);
        const yAxisYearFound = selectedYears.includes(yAxisYear);

        if (
          xAxisIndFound &&
          yAxisIndFound &&
          xAxisYearFound &&
          yAxisYearFound
        ) {
          return scatterChartSettings;
        }
      }

      const sortedSelectedYears = ControllerUtils.sortYears(selectedYears);

      if (manyIndicators && manyYears) {
        const [indicator1, indicator2] = indicators;
        const year1 = sortedSelectedYears[0],
          year2 = sortedSelectedYears[sortedSelectedYears.length - 1];

        return {
          ...scatterChartSettings,
          xAxisIndicator: indicator1,
          yAxisIndicator: indicator2,
          xAxisYear: year1,
          yAxisYear: year2,
        };
      } else if (manyIndicators && hasYears) {
        const [indicator1, indicator2] = indicators;
        const year = sortedSelectedYears[0];

        return {
          ...scatterChartSettings,
          xAxisIndicator: indicator1,
          yAxisIndicator: indicator2,
          xAxisYear: year,
          yAxisYear: year,
        };
      } else if (manyYears && hasIndicators) {
        const indicator = indicators[0];
        const year1 = sortedSelectedYears[0],
          year2 = sortedSelectedYears[sortedSelectedYears.length - 1];

        return {
          ...scatterChartSettings,
          xAxisIndicator: indicator,
          yAxisIndicator: indicator,
          xAxisYear: year1,
          yAxisYear: year2,
        };
      } else {
        const indicator = indicators[0],
          year = sortedSelectedYears[0];
        return {
          ...scatterChartSettings,
          xAxisIndicator: indicator,
          yAxisIndicator: indicator,
          xAxisYear: year,
          yAxisYear: year,
        };
      }
    }
  };

  //#endregion

  //#region Common
  static calculateAutoAxis(
    data: IndicatorDataEntry[],
    userDomain: AxisDomain
  ): AxisDomain {
    let domain = userDomain;

    const [d1, d2] = domain;

    if (d1 === 'dataMin' && d2 === 'dataMax') {
      const [min, max] = extent(data, (d) => d.value) as [number, number];

      const newMax = calculateNearBreak(max, 1);
      const newMin = calculateNearBreak(min, 0);

      return [Number(newMin), Number(newMax)];
    }
    return domain;
  }

  static getChartStoreProp(chart: AvailableCharts): keyof ChartsSettings {
    switch (chart) {
      case AvailableCharts.BarChart:
        return 'barChart';
      case AvailableCharts.TimeSerieChart:
        return 'timeSerie';
      case AvailableCharts.ScatterChart:
        return 'scatterChart';
      default:
        throw new Error(
          '[ChartController.getChartStoreProp] :: Unknown chart ' + chart
        );
    }
  }

  static async exportChartToPng(
    element: HTMLElement,
    title: string,
    options?: Options
  ): Promise<boolean> {
    try {
      if (ChartController.download_count === 0) {
        // Well, this is a dirty hack to avoid
        // some render issues with html2canvas.
        // We need to render the chart once before
        const ignoreCanvas = await html2canvas(element, options);
        ignoreCanvas.toDataURL('image/png');
        ChartController.download_count++;
        await delay(100);
      }
      const canvas = await html2canvas(element, options);

      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');

      a.href = url;
      a.download = `${title}.png`;
      a.click();
      a.remove();
      return true;
    } catch (err) {
    } finally {
      return false;
    }
  }

  static async exportChartToPdf(
    title: string,
    element: HTMLElement,
    master: BarChartSettings | ScatterChartSettings | TimeSerieChartSettings,
    comments?: IndicatorNotes,
    options?: HTMLOptions
  ): Promise<boolean> {
    try {
      const chartHeightAddition = isBarChartSettings(master)
        ? 50
        : isScatterChartSettings(master)
        ? 10
        : 0;

      const clonedEl = element.cloneNode(true) as HTMLDivElement;

      const elHeightStr = clonedEl.style.height;
      const elHeight = parseInt(elHeightStr.replace('px', ''));

      clonedEl.style.height = `${elHeight + chartHeightAddition}px`;

      document.body.append(clonedEl);

      const chartCanvas = await html2canvas(clonedEl);
      let lastY = PDF_CONFIG.TABLE_START_Y;

      const doc = new jsPDF('p', 'px', 'a4', true);

      doc.setFontSize(PDF_CONFIG.FONT_SIZE);
      const pageWidth = doc.internal.pageSize.getWidth();

      doc.setFontSize(18);
      const wrappedTitle = doc.splitTextToSize(
        title,
        PDF_CONFIG.MAX_TITLE_LENGTH
      );
      const titleHeight = wrappedTitle.length * PDF_CONFIG.TITLE_FONT_SIZE;
      doc.text(wrappedTitle, pageWidth / 2, lastY, { align: 'center' });
      lastY += titleHeight;

      doc.setFontSize(PDF_CONFIG.FONT_SIZE);

      const { width, height, x } = this.computeWidthHeight(
        pageWidth,
        chartCanvas
      );
      doc.addImage(chartCanvas, 'JPEG', x, lastY, width, height);
      lastY += height;

      // Add comments:
      addCommentsToDoc(doc, comments, lastY);

      doc.save(title);

      document.body.removeChild(clonedEl);
      clonedEl.remove();
      return true;
    } catch (err) {
      console.error(err);
    } finally {
      return false;
    }
  }

  static computeWidthHeight(
    pageWidth: number,
    chartCanvas: HTMLCanvasElement
  ): { width: number; height: number; x: number } {
    const maxWidth = pageWidth * 0.9; // Max width of the chart.
    const width = chartCanvas.width > maxWidth ? maxWidth : chartCanvas.width;
    const ratio = width / chartCanvas.width;
    return {
      width,
      height: chartCanvas.height * ratio,
      x: (pageWidth - width) / 2,
    };
  }

  static getValueLabel(
    raw: string,
    dissVar: DefaultSettingsSelectsOption,
    t: TFunction,
    acronym?: boolean
  ): string {
    let base: string, cat: string;

    let value: string = raw;
    if (value.includes('value_')) {
      value = value.replace('value_', '');
    }

    if (dissVar === 'INDICATOR') {
      base = 'indicators';
      cat = 'indicators';
    } else {
      base = 'variables';
      cat = ControllerUtils.getDefaultSettingsSelectsOptionDataProp(dissVar);
      if (cat === 'country' && acronym === true) {
        return value;
      }
      if (cat === 'year' && !isNaN(Number(value))) {
        return value;
      }
    }

    const key = `${base}:${cat}.${value}`.toLowerCase();
    return t(key, { defaultValue: value });
  }

  static _formatter = format('.2f');

  static axisFormat(value: string | number): string {
    if (isNaN(Number(value))) {
      return value as string;
    } else {
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return ChartController._formatter(Number(value));
      }
    }
  }

  static getGraphicTitle(
    chartType: AvailableCharts,
    selectedGraphic: MultiFilterOrIndicator,
    master: AnalysisBranch,
    t: TFunction
  ): string {
    switch (chartType) {
      case AvailableCharts.BarChart:
      case AvailableCharts.TimeSerieChart: {
        return ControllerUtils.getGraphicName(selectedGraphic, t);
      }
      case AvailableCharts.ScatterChart: {
        const { xAxisIndicator, xAxisYear, yAxisIndicator, yAxisYear } =
          master.chartsSettings.scatterChart;

        if (xAxisIndicator && xAxisYear && yAxisIndicator && yAxisYear) {
          const tXAxisIndicator = t(
            `indicators:indicators.${xAxisIndicator?.code}`
          );

          const tYAxisIndicator = t(
            `indicators:indicators.${yAxisIndicator?.code}`
          );

          const chartByValues = selectedGraphic.options.map((opt) => {
            return t(`variables:${opt.option}.${opt.value}`, {
              defaultValue: opt.value,
            });
          });

          const tChartBy = chartByValues[0] ?? '';

          return `${tXAxisIndicator} (${xAxisYear}) - ${tYAxisIndicator} (${yAxisYear}) · ${tChartBy}`;
        }
        return '';
      }
    }
  }

  static sortChartEntries(
    entry1: string | number,
    entry2: string | number
  ): number {
    if (isNaN(entry1 as any) && isNaN(entry2 as any)) {
      return (entry1 as string).localeCompare(entry2 as string);
    } else {
      return Number(entry1) - Number(entry2);
    }
  }

  static computeLeastSquares(
    xValues: number[],
    yValues: number[],
    domain?: [number, number]
  ): { x: number; y: number }[] {
    let Ex = 0,
      Ex2 = 0,
      Ey = 0,
      Exy = 0;

    const len = Math.min(xValues.length, yValues.length);

    for (let i = 0; i < len; i++) {
      const x = xValues[i];
      const y = yValues[i];
      const x2 = x ** 2;
      const xy = x * y;
      Ex += x;
      Ey += y;
      Exy += xy;
      Ex2 += x2;
    }

    const slope = (Exy - (Ex * Ey) / len) / (Ex2 - Ex ** 2 / len);

    const Mx = Ex / len,
      My = Ey / len;

    const intersection = My - slope * Mx;

    const points = [];

    const calcValues = domain ?? xValues;

    for (let i = 0; i < calcValues.length; i++) {
      const x = calcValues[i];
      const _y = slope * x + intersection;
      points.push({ x: x, y: _y });
    }

    return points;
  }

  //#endregion
}
