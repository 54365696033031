import React from 'react';
import { getInputOnChange, GetInputOnChangeHandler } from '.';

type SetterFn<T, K extends keyof T> = (key: K) => GetInputOnChangeHandler;

type RT<T, K extends keyof T> = [
  T,
  SetterFn<T, K>,
  React.Dispatch<React.SetStateAction<T>>
];

export const useFormData = <T>(initialFormData: T): RT<T, keyof T> => {
  const [formData, setFormData] = React.useState<T>(initialFormData);

  const setValue = <T, K extends keyof T>(key: K) =>
    getInputOnChange<T[K]>((value) => {
      // Check if key contains ., therefore it is a nested object
      if (key.constructor.name === 'String' && (key as string).includes('.')) {
        const keys = (key as string).split('.');
        const newFormData: any = { ...formData };
        let current = newFormData;
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (i === keys.length - 1) {
            current[key] = value;
            break;
          }
          if (!current[key]) {
            current[key] = {};
          }
          current = current[key];
        }
        setFormData({ ...newFormData });
      } else {
        setFormData({ ...formData, [key]: value });
      }
    });

  return [formData, setValue as SetterFn<T, keyof T>, setFormData];
};
