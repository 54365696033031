import React from 'react';
import { useClickPreventionOnDoubleClick } from './useClickPreventionDoubleClick';

export type LegendInteractionResultType = {
  hovered: string | null;
  hidden: string[];
  highlight: string[];
  shouldShow(code: string): boolean;
  shouldHide(code: string): boolean;
  getOpacity(code: string): number;
  isHidden(code: string): boolean;
  onChartLegendMouseEnter(evt: any): void;
  onChartLegendMouseLeave(evt: any): void;
  onChartLegendClick(evt: any): void;
  onChartLegendDoubleClick(evt: any): void;
};

export const useChartLegendInteractions = (): LegendInteractionResultType => {
  const [hovered, setHovered] = React.useState<string | null>(null);
  const [hidden, setHidden] = React.useState<string[]>([]);
  const [highlight, setHighlight] = React.useState<string[]>([]);

  const isHighlighted = React.useCallback(
    (code: string) => {
      return highlight.includes(code);
    },
    [highlight]
  );

  const isHidden = React.useCallback(
    (code: string) => {
      return Boolean(hidden.includes(code) && !isHighlighted(code));
    },
    [hidden, isHighlighted]
  );

  const shouldShow = React.useCallback(
    (code: string) => {
      return Boolean(
        !isHidden(code)
          ? highlight.length > 0
            ? isHighlighted(code)
            : true
          : false
      );
    },
    [isHidden, isHighlighted, highlight]
  );

  const shouldHide = React.useCallback(
    (code: string) => {
      return !shouldShow(code);
    },
    [shouldShow]
  );

  const onChartLegendMouseEnter = React.useCallback(
    (evt: any): void => {
      const value = evt.value;
      if (!shouldHide(value)) {
        setHovered(value || null);
      }
    },
    [setHovered, shouldHide]
  );

  const onChartLegendMouseLeave = React.useCallback(
    (evt: any): void => {
      setHovered(null);
    },
    [setHovered]
  );

  const _onChartLegendClick = React.useCallback(
    (evt: any): void => {
      const value = evt.value;
      if (hidden.includes(value)) {
        setHidden(hidden.filter((el: string) => el !== value));
      } else {
        if (highlight.length === 0) {
          setHidden(hidden.concat(value));
        } else {
          if (isHighlighted(value)) {
            if (highlight.length === 1) {
              setHidden([]);
              setHighlight([]);
            } else {
              setHighlight(highlight.filter((h) => h !== value));
            }
          } else if (highlight.length > 0) {
            setHighlight(highlight.concat(value));
          }
        }
      }
    },
    [setHidden, hidden, highlight, setHighlight, isHighlighted]
  );

  const _onChartLegendDoubleClick = React.useCallback(
    (evt: any): void => {
      const value = evt.value;
      if (isHighlighted(value)) {
        if (highlight.length === 1) {
          setHighlight([]);
          setHidden([]);
        } else {
          setHighlight(highlight.filter((h) => h !== value));
        }
      } else {
        setHighlight([value]);
        setHidden([]);
      }
    },
    [setHighlight, setHidden, highlight, isHighlighted]
  );

  const [onChartLegendClick, onChartLegendDoubleClick] =
    useClickPreventionOnDoubleClick(
      _onChartLegendClick,
      _onChartLegendDoubleClick
    );

  const getOpacity = React.useCallback(
    (code: string) => {
      return !hovered && shouldShow(code) ? 1 : hovered === code ? 1 : 0.3;
    },
    [hovered, shouldShow]
  );

  return {
    hovered,
    hidden,
    highlight,
    getOpacity,
    shouldHide,
    shouldShow,
    isHidden,
    onChartLegendMouseEnter,
    onChartLegendMouseLeave,
    onChartLegendClick,
    onChartLegendDoubleClick,
  };
};
