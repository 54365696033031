import { createReducer } from 'deox';

import * as Actions from './static.actions';

import { StaticStore } from 'domain/models';
import { CHSCategory } from 'domain/models/chs.models';

const getUrlCountry = () => {
  const search = new URLSearchParams(window.location.search);
  return search.get('country') ?? 'es';
};

export const initialState: StaticStore = {
  data: {
    countries: [],
    models: [],
  },
  selectedCategory: CHSCategory.HealthSystem,
  selectedCountry: getUrlCountry(),
};

export const staticReducer = createReducer(initialState, (handle) => [
  handle(Actions.setData, (state, { payload }) => ({
    ...state,
    data: payload.data,
  })),
  handle(Actions.setSelectedCountry, (state, { payload }) => ({
    ...state,
    selectedCountry: payload.country,
  })),
  handle(Actions.setSelectedCategory, (state, { payload }) => ({
    ...state,
    selectedCategory: payload.category,
  })),
]);
