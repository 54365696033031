import {
  BarChartSettings,
  ScatterChartSettings,
  TimeSerieChartSettings,
} from '.';
import { FilterEntry } from './filter.model';
import { MenuIndicator } from './indicator.model';

export const isMenuIndicator = (object: any): object is MenuIndicator => {
  if ('code' in object && 'variables' in object && 'source' in object) {
    return true;
  }
  return false;
};

export const isFilterEntry = (object: any): object is FilterEntry => {
  if ('code' in object && 'variable' in object && !('source' in object)) {
    return true;
  }
  return false;
};

export const isBarChartSettings = (object: any): object is BarChartSettings => {
  if ('orientation' in object && 'stackValues' in object) {
    return true;
  }
  return false;
};

export const isTimeSeriesSettings = (
  object: any
): object is TimeSerieChartSettings => {
  if ('movingAverage' in object && 'regressionLine' in object) {
    return true;
  }
  return false;
};

export const isScatterChartSettings = (
  object: any
): object is ScatterChartSettings => {
  if ('xAxisIndicator' in object && 'showDiagonal' in object) {
    return true;
  }
  return false;
};
