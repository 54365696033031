import { RowValue } from 'components';
import { KeyValue } from './common.models';

export type CharacteristicsHealthSystems = {
  countries: HealthSystem[];
  models: HealthSystemModel[];
};

export type HealthSystem = {
  country: string;
  memberSince: KeyValue<string>;
  population: KeyValue<string>;
  populationCoveredByPublicFunds: KeyValue<string>;
  financingSourceOfPublicHealth: KeyValue<string>;
  model: KeyValue<string>;
  centrallyDefinedServicePortfolio: KeyValue<string>;
  primaryCareFilterToSpecialists: KeyValue<string>;
  patientMushBeRegistered: KeyValue<string>;
  predominantModeOfProvisionPrimaryCare: KeyValue<string>;
  freeChoicePrimaryCarePhysician: KeyValue<string>;
  predominantModeOfProvisionOutpatientCare: KeyValue<string>;
  freeChoiceOutpatientSpecialized: KeyValue<string>;
  freeChoiceHospitalPhysician: KeyValue<string>;
  primaryCare2: KeyValue<string>;
  specialicedAmbulatoryCare: KeyValue<string>;
  hospitalAdmission: KeyValue<string>;
  laboratoryTests: KeyValue<string>;
  diagnosticImaging: KeyValue<string>;
  prescriptionDrugs: KeyValue<string>;
  primaryCare3: KeyValue<string>;
  specialicedAmbulatoryCare2: KeyValue<string>;
  hospital: KeyValue<string>;
};

export type HealthSystemModel = {
  name: string;
  type: KeyValue<string>;
  characteristics: KeyValue<string[]>;
};

export type HealthSystemProps = keyof HealthSystem;

export enum CHSCategory {
  HealthSystem = 'health_system',
  HealthCare = 'health_care',
  PatientCoPayment = 'patient_co-payment',
  RemunerationOfPhysicians = 'remuneration_of_physician',
}

export const CATEGORIES: CHSCategory[] = [
  CHSCategory.HealthSystem,
  CHSCategory.HealthCare,
  CHSCategory.PatientCoPayment,
  CHSCategory.RemunerationOfPhysicians,
];

export type CategoryEntry = {
  key: HealthSystemProps;
  col: RowValue;
};

export type CategoryConfig = Array<CategoryEntry>;
