/**
 * This method calculates the next best number to break a graph.
 * @param number The number to break
 * @param near   The near value to add to the number
 * @returns      The next best number to break the graph
 */
export const calculateNearBreak = (number: number, near: number) => {
  const decimals = Math.pow(10, Math.floor(Math.log10(number))); // Obtain the 'exponent part'. i.e. 100 = 2, 1000 = 3...

  // If the number is equals to decimals, we do not want to add the near value
  // For example, if number is 100, we do not want to add 1 to the near value (next tick will be 200)
  if (number === decimals && near === 1) {
    near = 0;
  }
  const nextMax = near + Math.floor(number / decimals); // Get the current significant figure (i.e. 82 = 8, 987 = 9)
  return decimals * nextMax;
};

/**
 * Number Utils class
 */
export class NumberUtils {
  public static min(...numbers: number[]): number {
    // NaN, infinity, -infinity safe
    const filtered = NumberUtils.validNumbers(numbers);
    return filtered.length > 0 ? Math.min(...filtered) : 0;
  }

  public static max(...numbers: number[]): number {
    // NaN, infinity, -infinity safe
    const filtered = NumberUtils.validNumbers(numbers);
    return filtered.length > 0 ? Math.max(...filtered) : 0;
  }

  public static validNumbers(numbers: number[]): number[] {
    return numbers.filter((n) => NumberUtils.isValidNumber(n));
  }

  public static isValidNumber(n: number): boolean {
    return !isNaN(n) && isFinite(n);
  }
};
