import i18next from 'i18next';
import i18nextBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Api } from '.';

i18next
  .use(i18nextBackend)
  .use(initReactI18next)
  .init({
    // ns: ['common', 'auth', 'settings'], // Uncomment this to load all Namespaces on init
    nsSeparator: ':',
    backend: {
      loadPath: () => {
        return `${Api.baseUrl}/locales/{{lng}}/{{ns}}.json`;
      },
      customHeaders: {
        Authorization: 'Basic cHJlZGljdGlhOmRlbW8=',
      },
    },
    fallbackLng: 'es',
    lng: 'es',
    debug: false, // process.env.NODE_ENV === 'development',
    cleanCode: true,
    load: 'languageOnly',
    partialBundledLanguages: true,
    react: {
      useSuspense: true,
    },
  });

const i18nextEnglish = i18next.cloneInstance();

export { i18next, i18nextEnglish };

export const Langs: { label: string; value: string; matchers: string[] }[] = [
  {
    label: 'common:langs.es.label',
    value: 'es',
    matchers: ['es', 'es-es'],
  },
  {
    label: 'common:langs.en.label',
    value: 'en',
    matchers: ['en', 'en-en', 'en-us'],
  },
];
