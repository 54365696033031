import { createReducer } from 'deox';

import * as Actions from './analysis.actions';

import { AnalysisBranch, AnalysisStore, AvailableCharts } from 'domain/models';
import compareBranches from './compareBranches';
import { defaultBranch } from './defaults';
import { checkStore, cleanUpStates } from './checkStore';
import { ChartController } from 'domain/utils/controllers';

export const initialState: AnalysisStore = {
  master: defaultBranch,
  draft: defaultBranch,
  hasChanges: false,
  chartSelected: AvailableCharts.BarChart,
  data: undefined,
  loading: false,
  showErrorsModal: false,
  errors: [],
};

export const analysisReducer = createReducer(initialState, (handle) => [
  handle(Actions.setSelectedIndicators, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        selectedIndicators: payload.indicators,
      },
      hasChanges: true,
    };

    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setSelectedFilters, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        selectedFilters: {
          ...state.draft.selectedFilters,
          [payload.variable]: payload.value,
        },
      },
      hasChanges: true,
    };
    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setTableSettings, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        tableSettings: payload.tableSettings,
      },
    };

    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setMapSettings, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        mapSettings: payload.mapSettings,
      },
    };

    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setChartsSettings, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        chartsSettings: payload.chartsSettings,
      },
    };
    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setBarChartSettings, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        chartsSettings: {
          ...state.draft.chartsSettings,
          barChart: payload.barChartSettings,
        },
      },
    };

    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setTimeSerieChartSettings, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        chartsSettings: {
          ...state.draft.chartsSettings,
          timeSerie: payload.timeSerieChartSettings,
        },
      },
    };

    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setScatterChartSettings, (state, { payload }) => {
    const newState: AnalysisStore = {
      ...state,
      draft: {
        ...state.draft,
        chartsSettings: {
          ...state.draft.chartsSettings,
          scatterChart: payload.scatterChartSettings,
        },
      },
    };

    const hasChanges = compareBranches(state.master, newState.draft);

    return { ...newState, hasChanges };
  }),
  handle(Actions.setActiveChart, (state, { payload }) => {
    return {
      ...state,
      chartSelected: payload.activeChart,
    };
  }),
  handle(Actions.commit, (state, { payload }) => {
    const scatterChartSettings =
      ChartController.ScatterChart.getInitialSettings(
        state.draft.selectedFilters.YEAR,
        state.draft.selectedIndicators,
        state.draft.chartsSettings.scatterChart
      );
    let draft: AnalysisBranch = {
      ...state.draft,
      chartsSettings: {
        ...state.draft.chartsSettings,
        scatterChart: scatterChartSettings,
      },
    };

    const { needsUpdate, newDraft } = cleanUpStates(draft);
    if (needsUpdate) {
      draft = newDraft;
    }

    const errors = checkStore({ ...state, draft });
    const blockDataLoad = errors.some((e) => !e.allowDataLoad);

    if (!blockDataLoad) {
      return {
        ...state,
        draft,
        master: draft,
        hasChanges: false,
        showErrorsModal: false,
        errors,
      };
    } else {
      return {
        ...state,
        loading: false,
        showErrorsModal: payload ? false : true,
        errors,
      };
    }
  }),
  handle(Actions.setData, (state, { payload }) => {
    return {
      ...state,
      data: payload.data,
    };
  }),
  handle(Actions.setLoading, (state, { payload }) => ({
    ...state,
    loading: payload,
  })),
  handle(Actions.setShowErrorsModal, (state, { payload }) => ({
    ...state,
    showErrorsModal: payload,
  })),
]);
