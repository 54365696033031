import { Api } from "domain/core";
import { DatasetList, IndicatorDataSource, ParamList } from "domain/models/data.models";
import { AuthorizedService } from "../authorized-service.base";
import { SignUrlService } from "./signurl.service";

export class WhoService extends AuthorizedService {

  public listDatasets(): Promise<DatasetList> {
    return this.fetch(Api.baseUrl + Api.data.who.indicators)
      .then(r => r.json());
  }

  public getParams(param: string): Promise<ParamList> {
    return this.fetch(`${Api.baseUrl}${Api.data.who.params}/${param}`)
      .then(r => r.json());
  }

  public getDownload(indicator: IndicatorDataSource): Promise<string> {
    const url = `${Api.baseUrl}${Api.data.who.export}/${indicator.dataset}`;
    return new SignUrlService().signUrl(url)
      .then(token => {
        return `${url}?token=${token}&indicator=${indicator.indicator}`;
    });
  }

  public getRawDownload(indicatorName: string): Promise<string> {
    const url = `${Api.baseUrl}${Api.data.who.export}/${indicatorName}`;
    return new SignUrlService().signUrl(url)
      .then(token => {
        return `${url}?token=${token}&indicator=${indicatorName}`;
    });
  }

}
