import {
  indicatorTitleFormatter,
  useIndicatorTitle,
} from 'domain/hooks/useIndicatorTitle';
import {
  FilterEntry,
  MenuIndicator,
  DefaultSettingsSelectsOption,
  IndicatorDataEntry,
  Variable,
  Nullable,
  References,
  REFERENCES,
  IndicatorUnit,
  IndicatorData,
} from 'domain/models';
import { TFunction } from 'i18next';

import { COUNTRY_MAPPING, COUNTRY_MAPPING_REVERSE } from './country_mappings';

export type PropCode = Nullable<string>;
export type FilterOrIndicator = FilterEntry | MenuIndicator;
export type FilterOrIndicatorKeys = keyof Pick<
  IndicatorDataEntry,
  'country' | 'indicator' | 'sex' | 'year'
>;
export type MultiFilterOrIndicatorOption = {
  option: FilterOrIndicatorKeys;
  value: string;
};
export type MultiFilterOrIndicator = {
  code: string;
  units: IndicatorUnit;
  options: MultiFilterOrIndicatorOption[];
};

export class ControllerUtils {
  static getVizByMultiOption(
    indicatorData: IndicatorData,
    by: DefaultSettingsSelectsOption[],
    indicators?: MenuIndicator[]
  ): MultiFilterOrIndicator[] {
    const dataProps = ControllerUtils.sortDefaultSettingsSelectedOptions(
      by
    ).map((b) => ControllerUtils.getDefaultSettingsSelectsOptionDataProp(b));

    let graphics: MultiFilterOrIndicator[] = [];

    let sexOnlyTotal = false;

    if (indicators && indicators.length > 0 && by[0] === Variable.Sex) {
      for (let ind of indicators) {
        if (!ind.variables.includes(Variable.Sex)) {
          sexOnlyTotal = true;
          break;
        }
      }
    }

    for (let entry of indicatorData.data) {
      let options: MultiFilterOrIndicatorOption[] = [];
      let codes: string[] = [];
      for (let dataProp of dataProps) {
        const prop = entry[dataProp] as PropCode;
        if (prop) {
          if (sexOnlyTotal) {
            if (
              dataProp === 'sex' &&
              (prop === null || prop?.toLocaleLowerCase() !== 'total')
            ) {
              continue;
            }
          }
          options.push({ option: dataProp, value: prop });
          codes.push(prop);
        }
      }
      const code = codes.reduce(
        (acc: string, curr: string) => `${acc}-${curr}`,
        ''
      );
      const alreadyInGraphics = graphics.find((g) => g.code === code);
      if (!alreadyInGraphics && options.length > 0) {
        graphics.push({
          code,
          units: indicatorData.units,
          options,
        });
      }
    }
    return graphics;
  }

  static getGraphicName(graphic: MultiFilterOrIndicator, t: TFunction): string {
    let name = '';

    for (let [index, opt] of graphic.options.entries()) {
      let separator = index === 0 ? '' : ' - ';
      if (opt.option === 'indicator') {
        const title = indicatorTitleFormatter({
          t: t,
          code: opt.value,
          unit: graphic.units[opt.value]?.code,
        });

        name += `${separator}${title}`;
      } else {
        name += `${separator}${t(
          `variables:${opt.option}.${opt.value}`.toLowerCase(),
          {
            defaultValue: opt.value,
          }
        )}`;
      }
    }

    return name;
  }

  static sortGraphicsYear(
    graphics: MultiFilterOrIndicator[]
  ): MultiFilterOrIndicator[] {
    // Extract year option
    return graphics.sort((g1, g2) => {
      const yearOptG1 = g1.options.find((o) => o.option === 'year');
      const yearOptG2 = g2.options.find((o) => o.option === 'year');

      if (yearOptG1 && yearOptG2) {
        const y1 = parseInt(yearOptG1.value || '0');
        const y2 = parseInt(yearOptG2.value || '0');

        return y1 - y2;
      }
      return 1;
    });
  }

  static sortGraphics(
    graphics: MultiFilterOrIndicator[],
    indicators?: MenuIndicator[]
  ): MultiFilterOrIndicator[] {
    const byIndicator = graphics.some((g) =>
      g.options.find((o) => o.option === 'indicator')
    );
    if (byIndicator && indicators) {
      return graphics.sort((g1, g2) => {
        const indOptG1 = g1.options.find((o) => o.option === 'indicator');
        const indOptG2 = g2.options.find((o) => o.option === 'indicator');

        if (indOptG1 && indOptG2) {
          const ind1 = indicators.find((i) => i.code === indOptG1.value);
          const ind2 = indicators.find((i) => i.code === indOptG2.value);

          if (ind1 && ind2) {
            return (
              ind1.domain.weight - ind2.domain.weight ||
              ind1.domain.dimensionWeight - ind2.domain.dimensionWeight ||
              ind1.weight - ind2.weight
            );
          }
        }

        return 1;
      });
    } else {
      return graphics;
    }
  }

  static getDefaultSettingsSelectsOptionDataProp(
    option: DefaultSettingsSelectsOption
  ): FilterOrIndicatorKeys {
    switch (option) {
      case Variable.Country:
        return 'country';
      case Variable.Sex:
        return 'sex';
      case Variable.Year:
        return 'year';
      case 'INDICATOR':
        return 'indicator';
      default:
        throw new Error(
          '[getDefaultSettingsSelectsOptionDataProp] :: Unknown option ' +
            option
        );
    }
  }

  static getRefereceData(
    data: IndicatorDataEntry[],
    ref: References,
    returnDefault: boolean = true
  ): IndicatorDataEntry | undefined {
    const occ = data.find((entry) => entry.country === ref);

    if (!occ && returnDefault) {
      return ControllerUtils.getReferencesData(data)[0];
    }

    return occ;
  }

  static getReferencesData(data: IndicatorDataEntry[]): IndicatorDataEntry[] {
    return data.filter((entry) => REFERENCES.includes(entry.country as any));
  }

  static isCountryRef(country: string): boolean {
    return REFERENCES.includes(country as any);
  }

  static getDefaultSettingsSelectsOptionByDataProp(
    option: FilterOrIndicatorKeys
  ): DefaultSettingsSelectsOption {
    switch (option) {
      case 'country':
        return Variable.Country;
      case 'sex':
        return Variable.Sex;
      case 'year':
        return Variable.Year;
      case 'indicator':
        return 'INDICATOR';
      default:
        throw new Error(
          '[getDefaultSettingsSelectsOptionDataProp] :: Unknown option ' +
            option
        );
    }
  }

  static getDefaultSettingsSelectedOptionsPrecedence(
    option: DefaultSettingsSelectsOption
  ): number {
    return {
      [Variable.Year]: 10,
      INDICATOR: 20,
      [Variable.Country]: 30,
      [Variable.Sex]: 40,
    }[option];
  }

  static sortDefaultSettingsSelectedOptions(
    options: DefaultSettingsSelectsOption[]
  ): DefaultSettingsSelectsOption[] {
    return options.sort(
      (o1, o2) =>
        ControllerUtils.getDefaultSettingsSelectedOptionsPrecedence(o1) -
        ControllerUtils.getDefaultSettingsSelectedOptionsPrecedence(o2)
    );
  }

  static getCountryMapping(country: string): string | undefined {
    return COUNTRY_MAPPING[country];
  }

  static getCountryMappingReverse(country: string): string | undefined {
    return COUNTRY_MAPPING_REVERSE[country];
  }

  static translateValue(
    value: string | number,
    variableOrIndicator: DefaultSettingsSelectsOption,
    t: TFunction
  ): string {
    let propName =
      ControllerUtils.getDefaultSettingsSelectsOptionDataProp(
        variableOrIndicator
      );
    if (propName === 'indicator') {
      return t(`indicators:indicators.${value}`);
    } else {
      return t(`variables:${propName}.${value}`.toLowerCase(), {
        defaultValue: value,
      });
    }
  }

  static isReferenceValue(country: string): boolean {
    return REFERENCES.includes(country.toUpperCase() as any);
  }

  static isSpain(country: string): boolean {
    return country.toUpperCase() === 'ES';
  }

  static isRefOrSpain(country: string): boolean {
    return (
      ControllerUtils.isReferenceValue(country) ||
      ControllerUtils.isSpain(country)
    );
  }

  /**
   * Función que devuelve el valor del año real. Por ejemplo, si tenemos los
   * años [2000, 2001, 2002, 2003, 2004] y le pedimos el año 'PRIMERO',
   * devolvera '2000', y si pedimos 'ULTIMO' devolverá '2004'
   */
  static getYearRealValue(data: IndicatorDataEntry[], year: string): string {
    if (!isNaN(Number(year))) {
      return year;
    }
    const values: string[] = data
      .map((d) => d.year)
      .filter((y) => !!y)
      .sort((y1, y2) => Number(y1) - Number(y2)) as string[];
    if (year === 'PRIMERO') {
      return values[0];
    } else {
      return values[values.length - 1];
    }
  }

  static getDataForSelectedGraphic(
    data: IndicatorDataEntry[],
    graphic: MultiFilterOrIndicator
  ): IndicatorDataEntry[] {
    let result: IndicatorDataEntry[] = [];
    for (let entry of data) {
      let pass = true;
      for (let option of graphic.options) {
        if (option.option === 'sex') {
          if (entry.sex && entry.sex !== option.value) {
            pass = false;
            continue;
          }
        } else {
          const dataProp = entry[option.option] as PropCode;
          if (!dataProp || dataProp !== option.value) {
            pass = false;
            continue;
          }
        }
      }
      if (pass) {
        result.push(entry);
      }
    }
    return result;
  }

  static getDissVarValues(
    data: IndicatorDataEntry[],
    dissVar: FilterOrIndicatorKeys
  ): string[] {
    let result: string[] = [];

    for (let entry of data) {
      let entryProp = entry[dissVar] as PropCode;

      if (entryProp && entryProp) {
        if (!result.includes(entryProp)) {
          result.push(entryProp);
        }
      }
    }

    return result;
  }

  static sortYears(years: string[]): string[] {
    const specialYears: any = {
      PRIMERO: 1,
      ULTIMO: Number.MAX_SAFE_INTEGER,
    };

    return years.sort((y1, y2) => {
      const ny1 = Number(y1);
      const ny2 = Number(y2);

      let yy1 = isNaN(ny1) ? specialYears[y1] : ny1;
      let yy2 = isNaN(ny2) ? specialYears[y2] : ny2;

      return yy1 - yy2;
    });
  }
}
