import { createActionCreator } from 'deox';
import {
  CharacteristicsHealthSystems,
  CHSCategory,
} from 'domain/models/chs.models';

export const SET_DATA = '[Static] :: Set data';
export const SET_SELECTED_COUNTRY = '[Static] :: Set selected country';
export const SET_SELECTED_CATEGORY = '[Static] :: Set selected category';

export const setData = createActionCreator(
  SET_DATA,
  (resolve) => (data: CharacteristicsHealthSystems) => resolve({ data })
);

export const setSelectedCountry = createActionCreator(
  SET_SELECTED_COUNTRY,
  (resolve) => (country: string) => resolve({ country })
);

export const setSelectedCategory = createActionCreator(
  SET_SELECTED_CATEGORY,
  (resolve) => (category: CHSCategory) => resolve({ category })
);
