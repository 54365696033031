import React from 'react';

export type GetInputOnChangeHandler = React.ChangeEventHandler<any> | any;

const isChangeEventHandler = (
  handler: any
): handler is React.ChangeEvent<any> =>
  typeof handler === 'object' && 'nativeEvent' in handler;

export function getInputOnChange<T>(
  setValue: React.Dispatch<React.SetStateAction<T>>
) {
  return (val: GetInputOnChangeHandler) => {
    if (isChangeEventHandler(val)) {
      const { currentTarget } = val;

      if (currentTarget.type === 'checkbox') {
        setValue((currentTarget as any).checked as any);
      } else {
        setValue(currentTarget.value as any);
      }
    } else {
      if (val && val.value) {
        setValue(val.value);
      } else {
        setValue(val);
      }
    }
  };
}

export function useInputState<T>(initialState: T) {
  const [value, setValue] = React.useState(initialState);
  return [value, getInputOnChange<T>(setValue)] as [
    T,
    (value: GetInputOnChangeHandler) => void
  ];
}
