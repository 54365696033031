import { AuthorizedService } from "../authorized-service.base";

export class BaseExportationService extends AuthorizedService {

  public static TYPE = {
    Complete: 'COMPLETE',
    Legacy: 'LEGACY'
  };

}
