import { CategoryConfig, CHSCategory } from 'domain/models/chs.models';

export const HealthSystemsConfig: CategoryConfig = [
  {
    key: 'memberSince',
    col: '1-3',
  },
  {
    key: 'population',
    col: '1-6',
  },
  {
    key: 'populationCoveredByPublicFunds',
    col: '1-2',
  },
  {
    key: 'financingSourceOfPublicHealth',
    col: '1-3',
  },
  {
    key: 'model',
    col: '2-3',
  },
];

export const HealthCareConfig: CategoryConfig = [
  {
    key: 'primaryCareFilterToSpecialists',
    col: '1-3',
  },
  {
    key: 'predominantModeOfProvisionPrimaryCare',
    col: '1-3',
  },
  {
    key: 'predominantModeOfProvisionOutpatientCare',
    col: '1-3',
  },
  {
    key: 'patientMushBeRegistered',
    col: '1-4',
  },
  {
    key: 'centrallyDefinedServicePortfolio',
    col: '1-4',
  },
  {
    key: 'freeChoicePrimaryCarePhysician',
    col: '1-4',
  },
  {
    key: 'freeChoiceOutpatientSpecialized',
    col: '1-4',
  },
  {
    key: 'freeChoiceHospitalPhysician',
    col: '1-3',
  },
];

export const PatientCoPaymentConfig: CategoryConfig = [
  {
    key: 'primaryCare2',
    col: '1-3',
  },
  {
    key: 'specialicedAmbulatoryCare',
    col: '1-3',
  },
  {
    key: 'hospitalAdmission',
    col: '1-3',
  },
  {
    key: 'laboratoryTests',
    col: '1-3',
  },
  {
    key: 'diagnosticImaging',
    col: '1-3',
  },
  {
    key: 'prescriptionDrugs',
    col: '1-3',
  },
];

export const RemunerationOfPhysicians: CategoryConfig = [
  {
    key: 'primaryCare3',
    col: '1-3',
  },
  {
    key: 'specialicedAmbulatoryCare2',
    col: '1-3',
  },
  {
    key: 'hospital',
    col: '1-3',
  },
];

export const categoriesConfig: { [category in CHSCategory]: CategoryConfig } = {
  [CHSCategory.HealthSystem]: HealthSystemsConfig,
  [CHSCategory.HealthCare]: HealthCareConfig,
  [CHSCategory.PatientCoPayment]: PatientCoPaymentConfig,
  [CHSCategory.RemunerationOfPhysicians]: RemunerationOfPhysicians,
};
